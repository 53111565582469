var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "popup__body" }, [
      _c("h4", { staticClass: "pdf-title" }, [
        _vm._v("\n      Данные из росреестра\n    ")
      ]),
      _c("table", { staticClass: "table table-sm table-borderless mb-4" }, [
        _c("tr", [
          _c("td", [_vm._v("Кадастровый номер:")]),
          _c("td", [_c("strong", [_vm._v(_vm._s(_vm.info.Number))])])
        ]),
        _c("tr", [
          _c("td", [_vm._v("Площадь:")]),
          _c("td", [
            _vm._v(_vm._s(_vm.info.Area) + "м"),
            _c("sup", [_vm._v("2")])
          ])
        ]),
        _c("tr", [
          _c("td", [_vm._v("Этаж:")]),
          _c("td", [_vm._v(_vm._s(_vm.info.Level))])
        ]),
        _c("tr", [
          _c("td", [_vm._v("Тип:")]),
          _c("td", [_vm._v(_vm._s(_vm.info.ObjectType))])
        ]),
        _c("tr", [
          _c("td", [_vm._v("Регион:")]),
          _c("td", [_vm._v(_vm._s(_vm.info.Region))])
        ]),
        _c("tr", [
          _c("td", [_vm._v("Адрес по документам:")]),
          _c("td", [_vm._v(_vm._s(_vm.info.Address))])
        ]),
        _c("tr", [
          _c("td", { staticClass: "pt-4" }, [
            _vm._v("\n          Кадастровая стоимость:\n        ")
          ]),
          _c("td", { staticClass: "pt-4" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm._f("currency")(_vm.info.KadPrice)))
            ])
          ])
        ]),
        _c("tr", [
          _c("td", [_vm._v("Дата определения стоимости:")]),
          _c("td", [_vm._v(_vm._s(_vm.info.DateOfKadReg))])
        ]),
        _c("tr", [
          _c("td", [_vm._v("Дата утверждения стоимости:")]),
          _c("td", [_vm._v(_vm._s(_vm.info.DateOfPriceAdded))])
        ])
      ]),
      _c("h4", { staticClass: "pdf-title" }, [
        _vm._v("\n      Данные из выписок\n    ")
      ]),
      _vm._m(0),
      _c("div", { staticClass: "pdf-row pdf-row_has-content" }, [
        _vm._m(1),
        _c("div", { staticClass: "pdf-row__content" }, [
          _c("div", { staticClass: "risk-list" }, [
            _c("div", { staticClass: "risk-list-group" }, [
              _c("b", [_vm._v("Долевая собственность - 1/3")]),
              _c("div", { staticClass: "risk-list-item" }, [
                _c(
                  "svg",
                  {
                    staticClass: "risk-list-item__icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                      }
                    }),
                    _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } })
                  ]
                ),
                _c("div", { staticClass: "risk-list-item__text" }, [
                  _vm._v(
                    "\n                Мухаммадиев Ильсур Шарифович\n                "
                  )
                ])
              ]),
              _c("div", { staticClass: "risk-list-item" }, [
                _c(
                  "svg",
                  {
                    staticClass: "risk-list-item__icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                      }
                    }),
                    _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } })
                  ]
                ),
                _c("div", { staticClass: "risk-list-item__text" }, [
                  _vm._v(
                    "\n                Мухаммадиев Дамир Ильсурович\n                "
                  )
                ])
              ]),
              _c("div", { staticClass: "risk-list-item" }, [
                _c(
                  "svg",
                  {
                    staticClass: "risk-list-item__icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                      }
                    }),
                    _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } })
                  ]
                ),
                _c("div", { staticClass: "risk-list-item__text" }, [
                  _vm._v(
                    "\n                Мухаммадиева Ландыш Фануровна\n                "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "pdf-row pdf-row_has-content" }, [
        _vm._m(2),
        _c("div", { staticClass: "pdf-row__content" }, [
          _c("div", { staticClass: "risk-list" }, [
            _c("div", { staticClass: "risk-list-group" }, [
              _c("b", { staticClass: "pl-0" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
                        }
                      }),
                      _c("path", {
                        attrs: { fill: "none", d: "M0 0h24v24H0z" }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("\n              05.05.2014\n            ")
                  ])
                ])
              ]),
              _vm._m(3)
            ]),
            _c("div", { staticClass: "risk-list-group" }, [
              _c("b", { staticClass: "pl-0" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
                        }
                      }),
                      _c("path", {
                        attrs: { fill: "none", d: "M0 0h24v24H0z" }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("\n              19.07.2014\n            ")
                  ])
                ])
              ]),
              _vm._m(4),
              _vm._m(5),
              _vm._m(6),
              _vm._m(7)
            ]),
            _c("div", { staticClass: "risk-list-group" }, [
              _c("b", { staticClass: "pl-0" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
                        }
                      }),
                      _c("path", {
                        attrs: { fill: "none", d: "M0 0h24v24H0z" }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("\n              26.09.2018\n            ")
                  ])
                ])
              ]),
              _vm._m(8)
            ]),
            _c("div", { staticClass: "risk-list-group" }, [
              _c("b", { staticClass: "pl-0" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
                        }
                      }),
                      _c("path", {
                        attrs: { fill: "none", d: "M0 0h24v24H0z" }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("\n              13.02.2019\n            ")
                  ])
                ])
              ]),
              _vm._m(9)
            ]),
            _c("div", { staticClass: "risk-list-group" }, [
              _c("b", { staticClass: "pl-0" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
                        }
                      }),
                      _c("path", {
                        attrs: { fill: "none", d: "M0 0h24v24H0z" }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("\n              13.02.2019\n            ")
                  ])
                ])
              ]),
              _vm._m(10)
            ]),
            _c("div", { staticClass: "risk-list-group" }, [
              _c("b", { staticClass: "pl-0" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
                        }
                      }),
                      _c("path", {
                        attrs: { fill: "none", d: "M0 0h24v24H0z" }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("\n              13.02.2019\n            ")
                  ])
                ])
              ]),
              _vm._m(11)
            ])
          ])
        ])
      ]),
      _c("h4", { staticClass: "pdf-title mt-4" }, [
        _vm._v("\n      Отчет о рисках\n    ")
      ]),
      _vm._m(12),
      _vm._m(13),
      _vm._m(14),
      _vm._m(15),
      _vm._m(16),
      _vm._m(17),
      _vm._m(18)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-row" }, [
      _c("div", { staticClass: "pdf-item" }, [
        _c("div", { staticClass: "pdf-item__icon" }, [
          _c("i", { staticClass: "fas fa-check-circle text-success" })
        ]),
        _c("div", { staticClass: "pdf-item__title" }, [
          _vm._v("\n          Наличие обременений\n        ")
        ]),
        _c("div", { staticClass: "pdf-item__right text-success" }, [
          _c("strong", [_vm._v("отсутсвуют")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-item" }, [
      _c("div", { staticClass: "pdf-item__icon" }, [
        _c("i", { staticClass: "fas fa-check-circle text-success" })
      ]),
      _c("div", { staticClass: "pdf-item__title" }, [
        _vm._v("\n          Собственники\n        ")
      ]),
      _c("div", { staticClass: "pdf-item__right text-success" }, [
        _c("strong", [_vm._v("3 собственника")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-item" }, [
      _c("div", { staticClass: "pdf-item__icon" }, [
        _c("i", { staticClass: "fas fa-check-circle text-success" })
      ]),
      _c("div", { staticClass: "pdf-item__title" }, [
        _vm._v("\n          История по правообладателям\n        ")
      ]),
      _c("div", { staticClass: "pdf-item__right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-list-item" }, [
      _c("div", { staticClass: "risk-list-item__text" }, [
        _vm._v("\n                Фазлеева Зинера Фагимовна\n                "),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(
            "\n                  Собственность - № 16-16-32/052/2014-500\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-list-item" }, [
      _c("div", { staticClass: "risk-list-item__text" }, [
        _vm._v("\n                Фазлеев Данис Булатович\n                "),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(
            "\n                  Долевая собственность, 1/12 - № 16-16-32/079/2014-529\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-list-item" }, [
      _c("div", { staticClass: "risk-list-item__text" }, [
        _vm._v(
          "\n                Фазлеева Ландыш Ильшатовна\n                "
        ),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(
            "\n                  Долевая собственность, 1/12 - № 16-16-32/079/2014-529\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-list-item" }, [
      _c("div", { staticClass: "risk-list-item__text" }, [
        _vm._v("\n                Фазлеев Самат Булатович\n                "),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(
            "\n                  Долевая собственность, 1/12 - № 16-16-32/079/2014-529\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-list-item" }, [
      _c("div", { staticClass: "risk-list-item__text" }, [
        _vm._v("\n                Фазлеев Булат Багзарович\n                "),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(
            "\n                  Долевая собственность, 1/12 - № 16-16-32/079/2014-529\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-list-item" }, [
      _c("div", { staticClass: "risk-list-item__text" }, [
        _vm._v("\n                Фазлеева Зинера Фагимовна\n                "),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(
            "\n                  Долевая собственность, 2/3 - № 16:52:040204:4081-16/022/2018-1\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-list-item" }, [
      _c("div", { staticClass: "risk-list-item__text" }, [
        _vm._v(
          "\n                Мухаммадиев Ильсур Шарифович\n                "
        ),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(
            "\n                  Долевая собственность, 1/3 - № 16:52:040204:4081-16/022/2019-7\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-list-item" }, [
      _c("div", { staticClass: "risk-list-item__text" }, [
        _vm._v(
          "\n                Мухаммадиев Дамир Ильсурович\n                "
        ),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(
            "\n                  Долевая собственность, 1/3 - № 16:52:040204:4081-16/022/2019-9\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-list-item" }, [
      _c("div", { staticClass: "risk-list-item__text" }, [
        _vm._v(
          "\n                Мухаммадиева Ландыш Фануровна\n                "
        ),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(
            "\n                  Долевая собственность, 1/3 - № 16:52:040204:4081-16/022/2019-8\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-row" }, [
      _c("div", { staticClass: "pdf-item" }, [
        _c("div", { staticClass: "pdf-item__icon" }, [
          _c("i", { staticClass: "fas fa-check-circle text-success" })
        ]),
        _c("div", { staticClass: "pdf-item__title" }, [
          _vm._v("\n          Розыск\n        ")
        ]),
        _c("div", { staticClass: "pdf-item__right" }, [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v("В базе данных розыска: ")
          ]),
          _c("strong", { staticClass: "text-success" }, [_vm._v("не числится")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-row" }, [
      _c("div", { staticClass: "pdf-item" }, [
        _c("div", { staticClass: "pdf-item__icon" }, [
          _c("i", { staticClass: "fas fa-check-circle text-success" })
        ]),
        _c("div", { staticClass: "pdf-item__title" }, [
          _vm._v("\n          Банкротство\n        ")
        ]),
        _c("div", { staticClass: "pdf-item__right" }, [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v("Реестр Банкротов: ")
          ]),
          _c("strong", { staticClass: "text-success" }, [
            _vm._v("отсутсвуют в реестре")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-row pdf-row_has-content" }, [
      _c("div", { staticClass: "pdf-item" }, [
        _c("div", { staticClass: "pdf-item__icon" }, [
          _c("i", { staticClass: "fas fa-exclamation-circle text-warning" })
        ]),
        _c("div", { staticClass: "pdf-item__title" }, [
          _vm._v("\n          Задолженность\n          "),
          _c("span", { staticClass: "text-muted" }, [
            _vm._v("Мухаммадиев Ильсур Шарифович")
          ])
        ]),
        _c("div", { staticClass: "pdf-item__right" })
      ]),
      _c("div", { staticClass: "pdf-row__content pt-3" }, [
        _c("div", { staticClass: "risk-metadata border-0" }, [
          _c("div", { staticClass: "risk-metadata-title bg-white p-0" }, [
            _vm._v(
              "\n            1. Задолженность по платежам за газ. тепло и электроэнергию\n          "
            )
          ]),
          _c("div", { staticClass: "risk-metadata-content pl-0 pr-0" }, [
            _c("div", { staticClass: "risk-metadata-item" }, [
              _c("div", { staticClass: "risk-metadata-item__title" }, [
                _vm._v(
                  "\n                Исполнительное производство\n              "
                )
              ]),
              _c("div", { staticClass: "risk-metadata-item__info-wrap" }, [
                _c("div", { staticClass: "risk-metadata-item__info" }, [
                  _c("div", { staticClass: "risk-metadata-item__label" }, [
                    _vm._v("\n                    Номер\n                  ")
                  ]),
                  _c("div", { staticClass: "risk-metadata-item__value" }, [
                    _vm._v(
                      "\n                    44562/19/02011 - ИП\n                  "
                    )
                  ])
                ]),
                _c("div", { staticClass: "risk-metadata-item__info" }, [
                  _c("div", { staticClass: "risk-metadata-item__label" }, [
                    _vm._v(
                      "\n                    Дата возбуждения\n                  "
                    )
                  ]),
                  _c("div", { staticClass: "risk-metadata-item__value" }, [
                    _vm._v(
                      "\n                    от 20.05.2019\n                  "
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "risk-metadata-item" }, [
              _c("div", { staticClass: "risk-metadata-item__title" }, [
                _vm._v(
                  "\n                Реквизиты исполнительного документа\n              "
                )
              ]),
              _c("div", { staticClass: "risk-metadata-item__info-wrap" }, [
                _c("div", { staticClass: "risk-metadata-item__info" }, [
                  _c("div", { staticClass: "risk-metadata-item__label" }, [
                    _vm._v("\n                    Вид\n                  ")
                  ]),
                  _c("div", { staticClass: "risk-metadata-item__value" }, [
                    _vm._v(
                      "\n                    Судебный приказ\n                  "
                    )
                  ])
                ]),
                _c("div", { staticClass: "risk-metadata-item__info" }, [
                  _c("div", { staticClass: "risk-metadata-item__label" }, [
                    _vm._v(
                      "\n                    Дата принятия\n                  "
                    )
                  ]),
                  _c("div", { staticClass: "risk-metadata-item__value" }, [
                    _vm._v(
                      "\n                    от 05.04.2019\n                  "
                    )
                  ])
                ]),
                _c("div", { staticClass: "risk-metadata-item__info" }, [
                  _c("div", { staticClass: "risk-metadata-item__label" }, [
                    _vm._v("\n                    Номер\n                  ")
                  ]),
                  _c("div", { staticClass: "risk-metadata-item__value" }, [
                    _vm._v(
                      "\n                    №2-606/2019\n                  "
                    )
                  ])
                ]),
                _c("div", { staticClass: "risk-metadata-item__info" }, [
                  _c("div", { staticClass: "risk-metadata-item__label" }, [
                    _vm._v(
                      "\n                    Наименование органа, выдавшего исполнительный документ\n                  "
                    )
                  ]),
                  _c("div", { staticClass: "risk-metadata-item__value" }, [
                    _vm._v(
                      "\n                    Судебный участок №2 по г.Набережные Челны Республики Татарстан\n                  "
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "risk-metadata-item" }, [
              _c("div", { staticClass: "risk-metadata-item__title" }, [
                _vm._v(
                  "\n                Судебный пристав-исполнитель\n              "
                )
              ]),
              _c("div", { staticClass: "risk-metadata-item__info" }, [
                _c("div", { staticClass: "risk-metadata-item__label" }),
                _c("div", { staticClass: "risk-metadata-item__value" }, [
                  _vm._v(
                    "\n                  Шайхуллина Н.З.\n                "
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-row" }, [
      _c("div", { staticClass: "pdf-item" }, [
        _c("div", { staticClass: "pdf-item__icon" }, [
          _c("i", { staticClass: "fas fa-check-circle text-success" })
        ]),
        _c("div", { staticClass: "pdf-item__title" }, [
          _vm._v("\n          Судебные разбирательства\n        ")
        ]),
        _c("div", { staticClass: "pdf-item__right" }, [
          _c("strong", { staticClass: "text-success" }, [_vm._v("отсутсвуют")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-row" }, [
      _c("div", { staticClass: "pdf-item" }, [
        _c("div", { staticClass: "pdf-item__icon" }, [
          _c("i", { staticClass: "fas fa-check-circle text-success" })
        ]),
        _c("div", { staticClass: "pdf-item__title" }, [
          _vm._v("\n          В списке на снос\n        ")
        ]),
        _c("div", { staticClass: "pdf-item__right" }, [
          _c("strong", { staticClass: "text-success" }, [_vm._v("не числится")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-row pdf-row_has-content" }, [
      _c("div", { staticClass: "pdf-item" }, [
        _c("div", { staticClass: "pdf-item__icon" }, [
          _c("i", { staticClass: "fas fa-exclamation-circle text-warning" })
        ]),
        _c("div", { staticClass: "pdf-item__title" }, [
          _vm._v("\n          Массовая регистрация\n        ")
        ]),
        _c("div", { staticClass: "pdf-item__right" })
      ]),
      _c("div", { staticClass: "pdf-row__content pt-3" }, [
        _c("div", { staticClass: "risk-registration-item" }, [
          _c("div", { staticClass: "risk-registration-item__name" }, [
            _vm._v('\n            ООО "РТ2"\n          ')
          ]),
          _c("div", { staticClass: "risk-registration-item__meta" }, [
            _c("div", { staticClass: "risk-registration-item__meta-item" }, [
              _c("div", { staticClass: "risk-registration-item__meta-title" }, [
                _vm._v("\n                ИНН:\n              ")
              ]),
              _c("div", { staticClass: "risk-registration-item__meta-value" }, [
                _vm._v("\n                1650338818\n              ")
              ])
            ]),
            _c("div", { staticClass: "risk-registration-item__meta-item" }, [
              _c("div", { staticClass: "risk-registration-item__meta-title" }, [
                _vm._v("\n                ОГРН:\n              ")
              ]),
              _c("div", { staticClass: "risk-registration-item__meta-value" }, [
                _vm._v("\n                1161690161786\n              ")
              ])
            ]),
            _c("div", { staticClass: "risk-registration-item__meta-item" }, [
              _c("div", { staticClass: "risk-registration-item__meta-title" }, [
                _vm._v(
                  "\n                Действующее, дата образования:\n              "
                )
              ]),
              _c("div", { staticClass: "risk-registration-item__meta-value" }, [
                _vm._v("\n                21.10.2016\n              ")
              ])
            ]),
            _c("div", { staticClass: "risk-registration-item__meta-item" }, [
              _c("div", { staticClass: "risk-registration-item__meta-title" }, [
                _vm._v(
                  "\n                Адрес регистрирующего органа:\n              "
                )
              ]),
              _c("div", { staticClass: "risk-registration-item__meta-value" }, [
                _vm._v(
                  "\n                423822, Респ Татарстан, г Набережные Челны, Набережночелнинский пр-кт, д 49, кв 18\n              "
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "risk-registration-item" }, [
          _c("div", { staticClass: "risk-registration-item__name" }, [
            _vm._v('\n            ООО "РЕАЛТИ КЛАУД"\n          ')
          ]),
          _c("div", { staticClass: "risk-registration-item__meta" }, [
            _c("div", { staticClass: "risk-registration-item__meta-item" }, [
              _c("div", { staticClass: "risk-registration-item__meta-title" }, [
                _vm._v("\n                ИНН:\n              ")
              ]),
              _c("div", { staticClass: "risk-registration-item__meta-value" }, [
                _vm._v("\n                1650367985\n              ")
              ])
            ]),
            _c("div", { staticClass: "risk-registration-item__meta-item" }, [
              _c("div", { staticClass: "risk-registration-item__meta-title" }, [
                _vm._v("\n                ОГРН:\n              ")
              ]),
              _c("div", { staticClass: "risk-registration-item__meta-value" }, [
                _vm._v("\n                1181690067932\n              ")
              ])
            ]),
            _c("div", { staticClass: "risk-registration-item__meta-item" }, [
              _c("div", { staticClass: "risk-registration-item__meta-title" }, [
                _vm._v(
                  "\n                Действующее, дата образования:\n              "
                )
              ]),
              _c("div", { staticClass: "risk-registration-item__meta-value" }, [
                _vm._v("\n                22.08.2018\n              ")
              ])
            ]),
            _c("div", { staticClass: "risk-registration-item__meta-item" }, [
              _c("div", { staticClass: "risk-registration-item__meta-title" }, [
                _vm._v(
                  "\n                Адрес регистрирующего органа:\n              "
                )
              ]),
              _c("div", { staticClass: "risk-registration-item__meta-value" }, [
                _vm._v(
                  "\n                423822, Респ Татарстан, г Набережные Челны, Набережночелнинский пр-кт, д 49, кв 18\n              "
                )
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "risk-conclusion risk-conclusion_expanded" },
      [
        _c("h5", { staticClass: "risk-conclusion__title" }, [
          _c("i", { staticClass: "fas fa-user-tie text-primary" }),
          _vm._v(" Экспертное заключение\n      ")
        ]),
        _c("div", { staticClass: "risk-conclusion__content" }, [
          _c("div", { staticClass: "risk-conclusion__content-wrap" }, [
            _c("p", [
              _c("strong", [_vm._v("Выявлена")]),
              _vm._v(
                " задолженность перед коммунальным предприятием. У\n            коммунальных предприятий есть возможность накладывать запрет на продажу объекта до\n            полного погашения долгов по квартплате. Если про долги и судебные разбирательства не\n            выявить заранее, то аванс или задаток в случае приостановке может быть потерян. Если\n            запрета на регистрационные действия нет, то долги не переходят к новому владельцу, а\n            числятся за прежним. Однако этот факт не исключает того, что новому собственнику\n            придется отстаивать свои права в коммунальных предприятиях и оспаривать начисление\n            долга ему.\n          "
              )
            ]),
            _c("p", [
              _vm._v(
                "\n            Рекомендуем покупать квартиру при погашенных коммунальных долгов. Если этой\n            возможности нет - рекомендуем на сумму задолженности снизить цену и покрыть ее\n            самостоятельно, не отдавая этот процесс на откуп продавцу.\n          "
              )
            ]),
            _c("hr"),
            _c("p", [
              _c("strong", [_vm._v("Выявлено")]),
              _vm._v(
                " наличие по адресу объекта зарегистрированного юридического\n            лица. Продать такую квартиру можно. Однако при переходе права собственности\n            юридическому лицу нужно будет запросить согласие всех собственников на то, чтобы\n            сохранить этот адрес. Новый собственник в праве это согласие не предоставлять. После\n            сделки уже к новому владельцу в почтовый ящик будет приходить корреспонденция или\n            могут прийти с проверкой. Рекомендуем до сделки согласовать с собственником перенос\n            юридического адреса. В некоторых случаях новый собственник может рассчитывать на\n            арендные платежи со стороны юрлица за предоставление адреса.\n          "
              )
            ]),
            _c("hr"),
            _c("p", { staticClass: "mb-0" }, [
              _c("strong", [_vm._v("Выявлено")]),
              _vm._v(
                ", что срок владения менее 5 (пяти) лет, текущий собственник\n            при продаже может попасть под налоги. В таком случае условием сделки со стороны\n            продавца может быть занижение стоимости в договоре. Рекомендуем указать реальную\n            стоимость. Также при сроке владения менее трех лет действует срок исковой давности.\n            Если предыдущая сделка прошла с нарушением или оспаривается есть риск признания сделки\n            недействительной. Рекомендуем нотариальное заполнение заверение об отсутствии\n            правопритязаний со стороны третьих лиц.\n          "
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "risk-conclusion__btn mt-2",
              staticStyle: { display: "none" }
            },
            [
              _c("span", { staticClass: "btn-link" }, [
                _vm._v("\n            Показать полностью "),
                _c("i", { staticClass: "fa fa-angle-down align-middle" })
              ])
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }