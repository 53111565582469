
















































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppObjectDemoPdfDialog',
  popupOptions: {
    name: 'popup_demo-pdf d-none d-print-block',
  },
  props: {
    info: Object,
    products: Array,
  },
})
export default class ObjectDemoPdf extends Vue {
  // props
  products: Product[];

  mounted() {
    window.print();
    this.$dialog.close();
  }
}
