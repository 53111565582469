import Vue from 'vue';
import Component from 'vue-class-component';
import pick from 'lodash/pick';
// import BaseOrderMixin from '../BaseOrder.mixin';
import { Validators } from '@/core/utils/validator';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';

import AppPropertyImage from '@/views/ObjectDetail/components/PropertyImage.vue';

let samples: Sample[];
const EARTH_RADIUS = 6372795;

function degreeToRadian(coords: number[]): number[] {
  const radians: number[] = [];

  for (const degree of coords) {
    radians.push((degree * Math.PI) / 180);
  }

  return radians;
}
function calcDistance(coordsOne: number[], coordsTwo: number[]): number {
  const [ lat1, long1 ] = degreeToRadian(coordsOne);
  const [ lat2, long2 ] = degreeToRadian(coordsTwo);
  const delta: number = long2 - long1;
  const sin: SimpleObject<number> = {
    delta: Math.sin(delta),
    lat1: Math.sin(lat1),
    lat2: Math.sin(lat2),
  };

  const cos: SimpleObject<number> = {
    delta: Math.cos(delta),
    lat1: Math.cos(lat1),
    lat2: Math.cos(lat2),
  };

  const x = sin.lat1 * sin.lat2 + cos.lat1 * cos.lat2 * cos.delta;
  const y = Math.sqrt(
    Math.pow(cos.lat2 * sin.delta, 2) +
      Math.pow(cos.lat1 * sin.lat2 - sin.lat1 * cos.lat2 * cos.delta, 2),
  );

  return Math.round(EARTH_RADIUS * Math.atan2(y, x));
}

@Component({
  name: 'AppAssessment',

  components: { AppPropertyImage },

  props: {
    info: Object,
  },

  filters: {
    formatMeter: (value: number) => {
      return value >= 1000 ? `${(value / 1000).toFixed(2)} км` : `${value} м`;
    },
  },
})
export default class AssessmentComponent extends Vue {
  // props
  info: RealtyObject;

  // data()
  loading: boolean = false;
  cost: number = null;
  productName: string = 'CostAssessment';
  buildingTypes: string[] = [ 'Панельный', 'Кирпичный', 'Монолитный', 'Блочный', 'Деревянный' ];
  response: any = null;
  samplesLimit: number = 5;
  model: EstimateModel = {
    area: null,
    level: null,
    levels: null,
    rooms: 3,
    address: null,
    object_type: 1,
    latitude: null,
    longitude: null,
    property_object_type_id: null,
    built_at: null,
    apartment_condition: 3,
  };

  // computed
  get sampleObjects(): any[] {
    if (this.response) {
      return samples.slice(0, this.samplesLimit);
    }

    return [];
  }

  // lifecycle
  async created() {
    if (this.info.Address) {
      this.model.address = this.info.Address;
    }

    if (this.info.Level) {
      this.model.level = this.info.Level;
    }

    if (this.info.Area) {
      this.model.area = this.info.Area as any;
    }

    const hInfo = this.info.HouseInfo;
    if (hInfo) {
      if (hInfo.levels > 0) {
        this.model.levels = hInfo.levels;
      }
    }

    this.fetchData();
  }

  // methods
  onSubmit() {}

  async fetchData() {
    this.loading = true;
    try {
      const r = await this.$api.request({
        url: `${location.origin}/data/object-estimate-demo.json`,
      });

      const filteredSamples = [];
      const samplesGroup = groupBy(r.samples, 'AdsID');

      for (const id in samplesGroup) {
        const items: Sample[] = samplesGroup[id];
        let item: Sample;

        if (items.length > 1) {
          const priceChanges: PriceChangeItem[] = [];

          const sortedSamples: Sample[] = orderBy(
            items,
            (i) => new Date(i.UploadAt).getTime(),
            'asc',
          );

          for (let index = 0, len = sortedSamples.length; index < len; index++) {
            const sample = sortedSamples[index];
            const prevSample = sortedSamples[index - 1];
            const priceChangeItem: PriceChangeItem = {
              date: sample.UploadAt,
              price: parseInt(sample.Price),
              difference: 0,
            };

            if (prevSample) {
              priceChangeItem.difference = parseInt(sample.Price) - parseInt(prevSample.Price);
            }

            priceChanges.push(priceChangeItem);
          }

          item = last(sortedSamples);

          if (!priceChanges.every((i) => i.difference === 0)) {
            item.PriceChanges = priceChanges;
          }
        } else {
          item = items[0];
        }

        item.Distance = calcDistance(
          [ this.model.latitude as any, this.model.longitude as any ],
          [ parseFloat(item.Latitude), parseFloat(item.Longitude) ],
        );

        filteredSamples.push(item);
      }

      const ca = parseFloat(this.model.area as any);

      samples = orderBy(
        filteredSamples,
        [ 'Distance', (i) => Math.abs(ca - parseFloat(i.Area)) ],
        [ 'asc', 'asc' ],
      );

      r.sample_size = samples.length;
      this.response = pick(r, 'cost', 'sample', 'sample_size');
    } catch (error) {
      console.error(error);
    }

    this.loading = false;
  }

  showMoreSamples() {
    this.samplesLimit += 10;
  }

  validationRules(): ValidationConfig {
    return {
      area: [ Validators.required ],
      level: [ Validators.required ],
      levels: [ Validators.required ],
      rooms: [ Validators.required ],
      address: [ Validators.required ],
      buildingType: [ Validators.required ],
    };
  }
}
