var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "object-detail-info" }, [
    _vm.objectInfo.ObjectType
      ? _c("div", { staticClass: "object-detail-info__type clearfix" }, [
          _c("div", { staticClass: "object-detail-info__type-label" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("shortObjectType")(_vm.objectInfo.ObjectType)) +
                "\n    "
            )
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "object-detail-info__number",
        class: { "object-detail-info__number_empty": !_vm.objectInfo.Number }
      },
      [_vm._v("\n    " + _vm._s(_vm.objectInfo.Number) + "\n  ")]
    ),
    _c("div", { staticClass: "object-detail-info__address" }, [
      _vm._v("\n    " + _vm._s(_vm.objectInfo.Address) + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }