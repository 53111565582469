var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-item product-item_purchased" }, [
    _c(
      "div",
      {
        staticClass: "product-item__left product-item__icon",
        class: {
          "text-success": _vm.isCompleted,
          "text-warning": !_vm.isCompleted
        }
      },
      [_c("svg", [_c("use", { attrs: { "xlink:href": _vm.iconStatus } })])]
    ),
    _c("div", { staticClass: "product-item__body" }, [
      _c("div", { staticClass: "product-item__name" }, [
        _vm._v("\n      " + _vm._s(_vm.item.product_name_ru) + "\n    ")
      ]),
      _c(
        "div",
        { staticClass: "product-item__desc" },
        [
          _vm.isCompleted
            ? [
                _vm._v(
                  "\n        Выполнено " +
                    _vm._s(
                      _vm._f("date")(_vm.item.created_date, "DD.MM.YYYY HH:mm")
                    ) +
                    "\n      "
                )
              ]
            : [
                _vm.requestTimeDiff && _vm.requestTimeDiff > 0
                  ? [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.remainingTimeMessage) +
                          "\n        "
                      )
                    ]
                  : [
                      _vm._v(
                        "\n          Росреестр перегружен, время выполнения увеличено\n          "
                      )
                    ]
              ]
        ],
        2
      )
    ]),
    _c("div", { staticClass: "product-item__right" }, [
      _vm.isCompleted
        ? _c(
            "div",
            { staticClass: "product-item__buttons d-flex" },
            [
              _c(
                "app-dropdown",
                { attrs: { direction: "left", "close-on-select": "" } },
                [
                  _c(
                    "app-button",
                    {
                      staticClass: "text-nowrap",
                      attrs: {
                        "data-dropdown-trigger": "",
                        variant: "primary-light",
                        size: "sm"
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-cloud-download-alt" }),
                      _vm._v(" Скачать\n        ")
                    ]
                  ),
                  _c(
                    "app-dropdown-menu",
                    { staticClass: "product-item__download-menu" },
                    [
                      _c("app-menu-item", [
                        _c(
                          "a",
                          {
                            staticClass: "text-reset text-decoration-none",
                            attrs: { href: _vm.item.pdf_link, download: "" }
                          },
                          [
                            _c("svg", [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-pdf" }
                              })
                            ]),
                            _vm._v("\n              pdf документ\n            ")
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("b", { staticClass: "text-nowrap" }, [_vm._v("В работе")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }