var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-list section", attrs: { id: "egrn" } },
    [
      _c("div", { staticClass: "wrapper-padding_sm" }, [
        _vm._m(0),
        _c("div", { staticClass: "obj-info" }, [
          _vm.info.Info
            ? _c("div", { staticClass: "obj-info__item" }, [
                _c("b", [_vm._v("Статус: ")]),
                _vm._v(" " + _vm._s(_vm.info.Info) + "\n      ")
              ])
            : _vm._e(),
          _vm.info.DateOfKadReg
            ? _c("div", { staticClass: "obj-info__item" }, [
                _c("b", [_vm._v("Дата постановки на учёт: ")]),
                _vm._v(" " + _vm._s(_vm.info.DateOfKadReg) + "\n      ")
              ])
            : _vm._e(),
          _vm.info.Area || _vm.info.AreaOKC
            ? _c("div", { staticClass: "obj-info__item" }, [
                _c("b", [_vm._v("Площадь: ")]),
                _vm._v(
                  " " +
                    _vm._s(_vm._f("float")(_vm.info.Area || _vm.info.AreaOKC)) +
                    " м"
                ),
                _c("sup", [_vm._v("2")])
              ])
            : _vm._e(),
          _vm.info.Level
            ? _c("div", { staticClass: "obj-info__item" }, [
                _c("b", [_vm._v("Этаж: ")]),
                _vm._v(" " + _vm._s(_vm.info.Level) + "\n      ")
              ])
            : _vm._e(),
          _vm.info.ObjectType
            ? _c("div", { staticClass: "obj-info__item" }, [
                _c("b", [_vm._v("Тип объекта: ")]),
                _vm._v(" " + _vm._s(_vm.info.ObjectType) + "\n      ")
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "collapse-wrapper wrapper-padding_sm border-bottom pt-0"
        },
        [_c("app-statements-info", { attrs: { purchases: _vm.purchases } })],
        1
      ),
      _vm.purchases && _vm.purchases.length
        ? _c(
            "div",
            { staticClass: " product-list__wrapper wrapper-padding_sm py-3" },
            [
              _c(
                "div",
                _vm._l(_vm.purchasesSlice, function(p) {
                  return _c("app-purchased-item", {
                    key: p.order_item_id,
                    attrs: { item: p },
                    on: {
                      "risk-click": function($event) {
                        return _vm.$emit("change-tab", "risk")
                      }
                    }
                  })
                }),
                1
              ),
              _vm.purchases.length > 2
                ? _c("div", { staticClass: "text-center" }, [
                    _c(
                      "span",
                      {
                        staticClass: "btn-link",
                        on: { click: _vm.toggleExpand }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.showAllPurchases
                                ? "Свернуть"
                                : "Показать все заказы"
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section__title" }, [
      _c("h5", [_vm._v("Данные из Росреестра")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }