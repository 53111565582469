

















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { EXAMPLE_TYPES } from '@/core/utils/constants/examples';

@Component({
  popupOptions: {
    size: 'lg',
  },
  props: {
    product: Object,
    description: String,
    info: Array,
    time: {
      type: String,
      default: '~12ч. (макс. 36ч.)',
    },
  },
})
export default class RiskInfo extends Vue {
  // props
  product: Product;
  // data()
  // computed
  get isRiskProduct(): boolean {
    return this.product.product_name === 'RiskAssessment';
  }
  // lifecycle hooks
  // methods
  showExamples(exampleType: string) {
    this.$photoSwipe((EXAMPLE_TYPES as any)[exampleType]);
  }
}
