var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "risk-meta-wrap" },
    [
      _c("app-dialog-close"),
      _c("div", { staticClass: "text-muted mb-0" }, [
        _vm._v("\n    Задолженности\n  ")
      ]),
      _c("h5", { staticClass: "mb-3" }, [
        _vm._v("\n    Мухаммадиев Ильсур Шарифович\n  ")
      ]),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-metadata" }, [
      _c("div", { staticClass: "risk-metadata-title" }, [
        _vm._v(
          "\n      1. Задолженность по платежам за газ. тепло и электроэнергию\n    "
        )
      ]),
      _c("div", { staticClass: "risk-metadata-content" }, [
        _c("div", { staticClass: "risk-metadata-item" }, [
          _c("div", { staticClass: "risk-metadata-item__title" }, [
            _vm._v("\n          Исполнительное производство\n        ")
          ]),
          _c("div", { staticClass: "risk-metadata-item__info-wrap" }, [
            _c("div", { staticClass: "risk-metadata-item__info" }, [
              _c("div", { staticClass: "risk-metadata-item__label" }, [
                _vm._v("\n              Номер\n            ")
              ]),
              _c("div", { staticClass: "risk-metadata-item__value" }, [
                _vm._v("\n              44562/19/02011 - ИП\n            ")
              ])
            ]),
            _c("div", { staticClass: "risk-metadata-item__info" }, [
              _c("div", { staticClass: "risk-metadata-item__label" }, [
                _vm._v("\n              Дата возбуждения\n            ")
              ]),
              _c("div", { staticClass: "risk-metadata-item__value" }, [
                _vm._v("\n              от 20.05.2019\n            ")
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "risk-metadata-item" }, [
          _c("div", { staticClass: "risk-metadata-item__title" }, [
            _vm._v("\n          Реквизиты исполнительного документа\n        ")
          ]),
          _c("div", { staticClass: "risk-metadata-item__info-wrap" }, [
            _c("div", { staticClass: "risk-metadata-item__info" }, [
              _c("div", { staticClass: "risk-metadata-item__label" }, [
                _vm._v("\n              Вид\n            ")
              ]),
              _c("div", { staticClass: "risk-metadata-item__value" }, [
                _vm._v("\n              Судебный приказ\n            ")
              ])
            ]),
            _c("div", { staticClass: "risk-metadata-item__info" }, [
              _c("div", { staticClass: "risk-metadata-item__label" }, [
                _vm._v("\n              Дата принятия\n            ")
              ]),
              _c("div", { staticClass: "risk-metadata-item__value" }, [
                _vm._v("\n              от 05.04.2019\n            ")
              ])
            ]),
            _c("div", { staticClass: "risk-metadata-item__info" }, [
              _c("div", { staticClass: "risk-metadata-item__label" }, [
                _vm._v("\n              Номер\n            ")
              ]),
              _c("div", { staticClass: "risk-metadata-item__value" }, [
                _vm._v("\n              №2-606/2019\n            ")
              ])
            ]),
            _c("div", { staticClass: "risk-metadata-item__info" }, [
              _c("div", { staticClass: "risk-metadata-item__label" }, [
                _vm._v(
                  "\n              Наименование органа, выдавшего исполнительный документ\n            "
                )
              ]),
              _c("div", { staticClass: "risk-metadata-item__value" }, [
                _vm._v(
                  "\n              Судебный участок №2 по г.Набережные Челны Республики Татарстан\n            "
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "risk-metadata-item" }, [
          _c("div", { staticClass: "risk-metadata-item__title" }, [
            _vm._v("\n          Судебный пристав-исполнитель\n        ")
          ]),
          _c("div", { staticClass: "risk-metadata-item__info" }, [
            _c("div", { staticClass: "risk-metadata-item__label" }),
            _c("div", { staticClass: "risk-metadata-item__value" }, [
              _vm._v("\n            Шайхуллина Н.З.\n          ")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }