var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "risk section", attrs: { id: "risk" } }, [
    _c(
      "div",
      { staticClass: "wrapper-padding_sm" },
      [
        _c("div", { staticClass: "section__title" }, [
          _c("h5", [_vm._v("Отчет о рисках")]),
          _c(
            "div",
            { staticClass: "section__title-info", on: { click: _vm.showInfo } },
            [
              _vm._v("\n        Об услуге "),
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#icon-fill-info" } })
              ])
            ]
          )
        ]),
        _c(
          "app-collapse",
          [
            _c(
              "app-collapse-item",
              { staticClass: "risk-collapse" },
              [
                _c("template", { slot: "title" }, [
                  _c("svg", { staticClass: "text-success" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-checkmark-circle" }
                    })
                  ]),
                  _c("span", { staticClass: "risk-collapse__title-text" }, [
                    _vm._v("Розыск")
                  ]),
                  _c(
                    "div",
                    {
                      directives: [{ name: "tippy", rawName: "v-tippy" }],
                      staticClass: "ml-auto mr-2"
                    },
                    [
                      _c("svg", [
                        _c("use", {
                          attrs: { "xlink:href": "#icon-outline-info" }
                        })
                      ]),
                      _c("div", { attrs: { "data-tippy-content": "hide" } }, [
                        _vm._v(
                          "\n              Проверка нахождения в розыске по базе МВД осуществляется по указанным в форме ФИО и\n              дате рождения.\n            "
                        )
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "risk-info-list" }, [
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиев Ильсур Шарифович\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("В базе данных розыска: ")]),
                      _c("strong", [_vm._v("не числится")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиев Дамир Ильсурович\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("В базе данных розыска: ")]),
                      _c("strong", [_vm._v("не числится")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиева Ландыш Фануровна\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("В базе данных розыска: ")]),
                      _c("strong", [_vm._v("не числится")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ])
                ])
              ],
              2
            ),
            _c(
              "app-collapse-item",
              { staticClass: "risk-collapse" },
              [
                _c("template", { slot: "title" }, [
                  _c("svg", { staticClass: "text-success" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-checkmark-circle" }
                    })
                  ]),
                  _c("span", { staticClass: "risk-collapse__title-text" }, [
                    _vm._v("Банкротство")
                  ])
                ]),
                _c("div", { staticClass: "risk-info-list" }, [
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиев Ильсур Шарифович\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("Реестр Банкротов: ")]),
                      _c("strong", [_vm._v("отсутсвуют в реестре")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиев Дамир Ильсурович\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("Реестр Банкротов: ")]),
                      _c("strong", [_vm._v("отсутсвуют в реестре")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиева Ландыш Фануровна\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("Реестр Банкротов: ")]),
                      _c("strong", [_vm._v("отсутсвуют в реестре")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ])
                ])
              ],
              2
            ),
            _c(
              "app-collapse-item",
              { staticClass: "risk-collapse" },
              [
                _c("template", { slot: "title" }, [
                  _c("svg", { staticClass: "text-warning" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-error-circle" } })
                  ]),
                  _c("span", { staticClass: "risk-collapse__title-text" }, [
                    _vm._v("Задолженность")
                  ])
                ]),
                _c("div", { staticClass: "risk-info-list" }, [
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиев Ильсур Шарифович\n              "
                      ),
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "span",
                          {
                            staticClass: "btn-link text-primary",
                            on: { click: _vm.showMeta }
                          },
                          [
                            _vm._v("\n                  Подробнее "),
                            _c("i", { staticClass: "fas fa-arrow-right fa-xs" })
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("Наличие задолженностей: ")]),
                      _c("strong", [_vm._v("имеется")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon fas fa-exclamation-circle text-danger fa-fw"
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиев Дамир Ильсурович\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("Наличие задолженностей: ")]),
                      _c("strong", [_vm._v("отсутсвуют")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиева Ландыш Фануровна\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("Наличие задолженностей: ")]),
                      _c("strong", [_vm._v("отсутсвуют")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ])
                ])
              ],
              2
            ),
            _c(
              "app-collapse-item",
              { staticClass: "risk-collapse" },
              [
                _c("template", { slot: "title" }, [
                  _c("svg", { staticClass: "text-success" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-checkmark-circle" }
                    })
                  ]),
                  _c("span", { staticClass: "risk-collapse__title-text" }, [
                    _vm._v("Судебные разбирательства")
                  ])
                ]),
                _c("div", { staticClass: "risk-info-list" }, [
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиев Ильсур Шарифович\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("Судебные дела: ")]),
                      _c("strong", [_vm._v("отсутсвуют")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиев Дамир Ильсурович\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("Судебные дела: ")]),
                      _c("strong", [_vm._v("отсутсвуют")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "risk-info-list__item" }, [
                    _c("div", { staticClass: "risk-info-list__person-name" }, [
                      _vm._v(
                        "\n              Мухаммадиева Ландыш Фануровна\n            "
                      )
                    ]),
                    _c("div", { staticClass: "risk-info-list__status" }, [
                      _c("span", [_vm._v("Судебные дела: ")]),
                      _c("strong", [_vm._v("отсутсвуют")]),
                      _c("i", {
                        staticClass:
                          "risk-info-list__status-icon far fa-check-circle fa-fw"
                      })
                    ])
                  ])
                ])
              ],
              2
            ),
            _c(
              "app-collapse-item",
              { staticClass: "risk-collapse", attrs: { disabled: "" } },
              [
                _c("template", { slot: "title" }, [
                  _c("svg", { staticClass: "text-success" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-checkmark-circle" }
                    })
                  ]),
                  _c("span", { staticClass: "risk-collapse__title-text" }, [
                    _vm._v("В списке на снос")
                  ]),
                  _c("span", { staticClass: "ml-auto" }, [
                    _c("strong", { staticClass: "text-success" }, [
                      _vm._v("не числится")
                    ])
                  ])
                ])
              ],
              2
            ),
            _c(
              "app-collapse-item",
              { staticClass: "risk-collapse" },
              [
                _c("template", { slot: "title" }, [
                  _c("svg", { staticClass: "text-warning" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-error-circle" } })
                  ]),
                  _c("span", { staticClass: "risk-collapse__title-text" }, [
                    _vm._v("Массовая регистрация")
                  ])
                ]),
                _c("div", { staticClass: "risk-registration-item" }, [
                  _c("div", { staticClass: "risk-registration-item__name" }, [
                    _vm._v('\n            ООО "РТ2"\n          ')
                  ]),
                  _c("div", { staticClass: "risk-registration-item__meta" }, [
                    _c(
                      "div",
                      { staticClass: "risk-registration-item__meta-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-title" },
                          [_vm._v("\n                ИНН:\n              ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-value" },
                          [
                            _vm._v(
                              "\n                1650338818\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "risk-registration-item__meta-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-title" },
                          [_vm._v("\n                ОГРН:\n              ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-value" },
                          [
                            _vm._v(
                              "\n                1161690161786\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "risk-registration-item__meta-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-title" },
                          [
                            _vm._v(
                              "\n                Действующее, дата образования:\n              "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-value" },
                          [
                            _vm._v(
                              "\n                21.10.2016\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "risk-registration-item__meta-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-title" },
                          [
                            _vm._v(
                              "\n                Адрес регистрирующего органа:\n              "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-value" },
                          [
                            _vm._v(
                              "\n                423822, Респ Татарстан, г Набережные Челны, Набережночелнинский пр-кт, д 49, кв 18\n              "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "risk-registration-item" }, [
                  _c("div", { staticClass: "risk-registration-item__name" }, [
                    _vm._v('\n            ООО "РЕАЛТИ КЛАУД"\n          ')
                  ]),
                  _c("div", { staticClass: "risk-registration-item__meta" }, [
                    _c(
                      "div",
                      { staticClass: "risk-registration-item__meta-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-title" },
                          [_vm._v("\n                ИНН:\n              ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-value" },
                          [
                            _vm._v(
                              "\n                1650367985\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "risk-registration-item__meta-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-title" },
                          [_vm._v("\n                ОГРН:\n              ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-value" },
                          [
                            _vm._v(
                              "\n                1181690067932\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "risk-registration-item__meta-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-title" },
                          [
                            _vm._v(
                              "\n                Действующее, дата образования:\n              "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-value" },
                          [
                            _vm._v(
                              "\n                22.08.2018\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "risk-registration-item__meta-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-title" },
                          [
                            _vm._v(
                              "\n                Адрес регистрирующего органа:\n              "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "risk-registration-item__meta-value" },
                          [
                            _vm._v(
                              "\n                423822, Респ Татарстан, г Набережные Челны, Набережночелнинский пр-кт, д 49, кв 18\n              "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "wrapper-padding_sm pt-0 pb-0" }, [
      _c(
        "div",
        {
          staticClass: "risk-conclusion",
          class: { "risk-conclusion_expanded": _vm.expandConclusion }
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "risk-conclusion__content" }, [
            _vm._m(1),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.expandConclusion,
                    expression: "!expandConclusion"
                  }
                ],
                staticClass: "risk-conclusion__btn mt-2"
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "btn-link",
                    on: {
                      click: function($event) {
                        _vm.expandConclusion = true
                      }
                    }
                  },
                  [
                    _vm._v("\n            Показать полностью "),
                    _c("i", { staticClass: "fa fa-angle-down align-middle" })
                  ]
                )
              ]
            )
          ])
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "product-list__wrapper wrapper-padding_sm pt-0" },
      [
        _c("div", { staticClass: "product-item product-item_default mb-0" }, [
          _c(
            "div",
            {
              staticClass: "product-item__left product-item__icon text-success"
            },
            [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#icon-checkmark-circle" } })
              ])
            ]
          ),
          _vm._m(2),
          _vm._m(3)
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "risk-conclusion__title" }, [
      _c("i", { staticClass: "fas fa-user-tie text-primary" }),
      _vm._v(" Экспертное заключение\n      ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-conclusion__content-wrap" }, [
      _c("p", [
        _c("strong", [_vm._v("Выявлена")]),
        _vm._v(
          " задолженность перед коммунальным предприятием. У\n            коммунальных предприятий есть возможность накладывать запрет на продажу объекта до\n            полного погашения долгов по квартплате. Если про долги и судебные разбирательства не\n            выявить заранее, то аванс или задаток в случае приостановке может быть потерян. Если\n            запрета на регистрационные действия нет, то долги не переходят к новому владельцу, а\n            числятся за прежним. Однако этот факт не исключает того, что новому собственнику\n            придется отстаивать свои права в коммунальных предприятиях и оспаривать начисление\n            долга ему.\n          "
        )
      ]),
      _c("p", [
        _vm._v(
          "\n            Рекомендуем покупать квартиру при погашенных коммунальных долгов. Если этой\n            возможности нет - рекомендуем на сумму задолженности снизить цену и покрыть ее\n            самостоятельно, не отдавая этот процесс на откуп продавцу.\n          "
        )
      ]),
      _c("hr"),
      _c("p", [
        _c("strong", [_vm._v("Выявлено")]),
        _vm._v(
          " наличие по адресу объекта зарегистрированного юридического\n            лица. Продать такую квартиру можно. Однако при переходе права собственности\n            юридическому лицу нужно будет запросить согласие всех собственников на то, чтобы\n            сохранить этот адрес. Новый собственник в праве это согласие не предоставлять. После\n            сделки уже к новому владельцу в почтовый ящик будет приходить корреспонденция или\n            могут прийти с проверкой. Рекомендуем до сделки согласовать с собственником перенос\n            юридического адреса. В некоторых случаях новый собственник может рассчитывать на\n            арендные платежи со стороны юрлица за предоставление адреса.\n          "
        )
      ]),
      _c("hr"),
      _c("p", { staticClass: "mb-0" }, [
        _c("strong", [_vm._v("Выявлено")]),
        _vm._v(
          ", что срок владения менее 5 (пяти) лет, текущий собственник\n            при продаже может попасть под налоги. В таком случае условием сделки со стороны\n            продавца может быть занижение стоимости в договоре. Рекомендуем указать реальную\n            стоимость. Также при сроке владения менее трех лет действует срок исковой давности.\n            Если предыдущая сделка прошла с нарушением или оспаривается есть риск признания сделки\n            недействительной. Рекомендуем нотариальное заполнение заверение об отсутствии\n            правопритязаний со стороны третьих лиц.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-item__body" }, [
      _c("div", { staticClass: "product-item__name" }, [
        _vm._v("\n          Отчет о рисках\n        ")
      ]),
      _c("div", { staticClass: "product-item__desc" }, [
        _c("span", [
          _vm._v("\n            Сроки 1-3 дня. PDF документ\n            ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-item__right" }, [
      _c("div", { staticClass: "product-item__info" }, [
        _c("b", [_vm._v("Выполнен")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }