var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "object-detail" },
    [
      _c("app-product-list", {
        attrs: {
          info: _vm.info,
          purchases: _vm.purchases,
          products: _vm.products
        }
      }),
      _c("app-risk", { attrs: { info: _vm.info, checkout: _vm.checkout } }),
      _c("app-assessment", {
        attrs: { info: _vm.info, checkout: _vm.checkout }
      }),
      _c("app-sales-history", { attrs: { info: _vm.info } }),
      _c(
        "div",
        {
          staticClass: "section wrapper-padding_sm obj-stats d-block d-lg-none"
        },
        [
          _c("div", { staticClass: "obj-stats__title mb-2 text-center" }, [
            _vm._v("\n      Информация по объекту\n    ")
          ]),
          _vm._m(0),
          _vm._m(1),
          _c(
            "app-button",
            {
              attrs: { size: "lg", block: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("print-pdf")
                }
              }
            },
            [_vm._v("\n      Скачать PDF\n    ")]
          )
        ],
        1
      ),
      _c("app-object-detail-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "obj-stats-progress" }, [
      _c("div", { staticClass: "obj-stats-progress__progress progress" }, [
        _c("div", {
          staticClass: "obj-stats-progress__bar progress-bar",
          staticStyle: { width: "100%" }
        })
      ]),
      _c(
        "div",
        {
          staticClass: "obj-stats-progress__total text-muted small text-center"
        },
        [_vm._v("\n        Открыто 100% из 100%\n      ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "obj-stats-list pl-3 mt-2" }, [
      _c("li", [
        _c("div", { staticClass: "obj-stats-list__item py-1" }, [
          _vm._v("\n          Данные из Росреестра "),
          _c("span", { staticClass: "text-success small" }, [_vm._v("+30%")])
        ])
      ]),
      _c("li", [
        _c("div", { staticClass: "obj-stats-list__item py-1" }, [
          _vm._v("\n          Отчет о рисках "),
          _c("span", { staticClass: "text-success small" }, [_vm._v("+40%")])
        ])
      ]),
      _c("li", [
        _c("div", { staticClass: "obj-stats-list__item py-1" }, [
          _vm._v("\n          Отчет о стоимости "),
          _c("span", { staticClass: "text-success small" }, [_vm._v("+15%")])
        ])
      ]),
      _c("li", [
        _c("div", { staticClass: "obj-stats-list__item py-1" }, [
          _vm._v("\n          История продаж "),
          _c("span", { staticClass: "text-success small" }, [_vm._v("+15%")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }