import Vue from 'vue';
import Component from 'vue-class-component';
import ProductInfo from 'shared/components/Product/ProductInfoDialog.vue';

@Component({
  name: 'AppProductItem',

  props: {
    product: Object,
  },
})
export default class ProductItem extends Vue {
  // props
  product: Product;

  // data
  info: string[] = [];
  description: string = '';

  // computed
  get selected(): string[] {
    return this.$store.state.order.items;
  }

  set selected(value: string[]) {
    this.$store.commit('order/changeItem', value);
  }

  get active(): boolean {
    return this.selected.some((v: string) => v === this.product.product_id);
  }

  get coupon(): Coupon | null {
    if (this.product.coupons) {
      return this.product.coupons[0];
    }

    return null;
  }

  created() {
    switch (this.product.product_name) {
      case 'EgrnObject': {
        this.$store.commit('order/changeItem', [ this.product.product_id ]);

        this.description =
          'Данный документ содержит в себе всю необходимую информацию о владельце недвижимости. В нем также указано наличие обременений на недвижимое имущество. Главная информация из отчеты ЕГРН:';
        this.info = [
          'Основные характеристики (адрес, площадь, тип, кадастр. стоимость...)',
          'ФИО настоящего владельца (если права зарегистрированы)',
          'Вид права на недвижимость, серию и номер свидетельства на собственность',
          'Наличие / отсутствие обременений',
          'Информация о правопритязаниях (при наличии)',
          // '- Номер кадастрового квартала.',
          // '- Дата присвоения кадастрового номера.',
          // '- Ранее присвоенный государственный учетный номер.',
          // '- Адрес и Площадь.',
          // '- Назначение и наименование.',
          // '- Количество этажей, в том числе подземных этажей.',
          // '- Материал наружных стен.',
          // '- Год завершения строительства.',
          // '- Кадастровая стоимость, руб.',
          // '- Сведения о кадастровом инженере.',
          // '- Правообладатель (правообладатели).',
          // '- Вид, номер и дата государственной регистрации права.',
          // '- Ограничение прав и обременение объекта недвижимости',
        ];
        break;
      }
      case 'EgrnRightList': {
        this.description =
          'Данная выписка содержит полную информацию о бывших владельцах, а также даты перехода прав и причины их смены.';
        this.info = [
          'ФИО всех сосбственников с 1998 года',
          'Серию и номер свидетельства о регистрации',
          'Даты перехода прав',
          'Дополнительная информация',
          'Данные о каждом из Правообладателей.',
          // '- История перехода прав.',
          // '- Вид, номер, дата и основание государственной регистрации.',
          // '- перехода(прекращения) права.',
          // '- Номер, тип этажа, на котором расположено помещение, машино-место.',
          // '- Вид жилого помещения.',
          // '- Адрес и Кадастровый номер',
        ];
        break;
      }
    }
  }

  // methods
  openInfoDialog() {
    this.$dialog.open({
      component: ProductInfo,
      props: {
        info: this.info,
        product: this.product,
        description: this.description,
      },
    });
  }
}
