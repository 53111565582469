




































































































import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import AppProductItem from '@/views/ObjectDetailDemo/components/ProductItem/ProductItem';
import AppStatementsInfo from '@/views/ObjectDetailDemo/components/StatementsInfo.vue';
import AppPurchasedItem from '@/views/ObjectDetailDemo/components/ProductItem/PurchasedProductItem.vue';

import BaseOrderMixin from '../BaseOrder.mixin';

@Component({
  name: 'AppProductList',

  components: {
    AppProductItem,
    AppPurchasedItem,
    AppStatementsInfo,
  },

  props: {
    products: Array,
    purchases: Array,
    info: Object,
  },

  computed: {
    ...mapGetters('order', [ 'totalAmount', 'itemsCount' ]),
  },
})
export default class ProductList extends mixins(BaseOrderMixin) {
  // props
  products: Product[];
  purchases: Product[];
  info: RealtyObject;

  // data()
  showAllPurchases: boolean = false;

  // computed
  totalAmount: number;
  itemsCount: number;

  get purchasesSlice() {
    if (this.purchases.length > 2 && !this.showAllPurchases) {
      return this.purchases.slice(0, 2);
    }

    return this.purchases;
  }

  get officialDocuments(): Product[] {
    return this.products.filter((p: Product) => {
      return p.product_type === 'official_documents';
    });
  }

  // methods
  toggleExpand() {
    this.showAllPurchases = !this.showAllPurchases;
  }
}
