



































































import Vue from 'vue';
import Component from 'vue-class-component';

import AppObjectDetailInfo from '@/views/ObjectDetailDemo/components/ObjectDetailInfo.vue';
import AppObjectDetailFooter from '@/views/ObjectDetail/components/ObjectDetailFooter.vue';

import AppRisk from '@/views/ObjectDetailDemo/Tabs/Risk/Risk';
import AppAssessment from '@/views/ObjectDetailDemo/Tabs/Assessment/Assessment';
import AppDetailsList from '@/views/ObjectDetailDemo/Tabs/DetailsList.vue';
import AppProductList from '@/views/ObjectDetailDemo/Tabs/ProductList/ProductList.vue';
import AppSalesHistory from '@/views/ObjectDetailDemo/Tabs/SalesHistory/SalesHistory';

@Component({
  components: {
    AppObjectDetailInfo,
    AppObjectDetailFooter,
    AppAssessment,
    AppDetailsList,
    AppProductList,
    AppSalesHistory,
    AppRisk,
  },

  props: {
    products: Array,
    purchases: Array,
    info: Object,
  },
})
export default class ObjectDetail extends Vue {
  info: RealtyObject;

  // methods
  checkout() {}
}
