import Component, { mixins } from 'vue-class-component';
import BaseOrderMixin from '../BaseOrder.mixin';
import AppRiskInfoDialog from './RiskInfo.vue';
import AppRiskMetadataDialog from './RiskMetadata.vue';
import { RISK_EXAMPLES } from '@/core/utils/constants/examples';

@Component({
  name: 'AppRisk',

  // props: {
  //   info: Object,
  //   // products: Array,
  // },
})
export default class RcRisk extends mixins(BaseOrderMixin) {
  // data()
  productName: string = 'RiskAssessment';
  expandConclusion: boolean = false;

  // methods
  showExamples() {
    return this.$photoSwipe(RISK_EXAMPLES);
  }

  showInfo() {
    const time = '~12ч. (макс. 72ч.)';
    const description = 'Данный отчет содержит полную информацию о недвижимости и собственниках:';
    const info = [
      'ФИО всех сосбтвенников с 1998 года',
      'Серию и номер свидетельства о регистрации',
      'Даты перехода прав',
      'Обременения (арест/залог)',
      'Кадастровую стоимость',
      'Задолженность собственников',
      'Проверка действительности паспорта',
      'Банкротство',
      'Судебные разбирательства',
      'В списке на снос',
      'Массовая регистрация',
    ];
    this.$dialog.open({
      component: AppRiskInfoDialog,
      props: {
        product: this.product,
        description,
        time,
        info,
      },
    });
  }

  showMeta() {
    this.$dialog.open({
      component: AppRiskMetadataDialog,
    });
  }
}
