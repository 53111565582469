var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-collapse",
    [
      _c(
        "app-collapse-item",
        { staticClass: "risk-collapse", attrs: { disabled: !_vm.encumbrance } },
        [
          _c("template", { slot: "title" }, [
            !_vm.egrnObject
              ? _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#icon-lock" } })
                ])
              : _vm.encumbrance
              ? _c("svg", { staticClass: "text-warning" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-minus-circle" } })
                ])
              : _c("svg", { staticClass: "text-success" }, [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-checkmark-circle" }
                  })
                ]),
            _c("span", { staticClass: "risk-collapse__title-text" }, [
              _vm._v("Обременения")
            ]),
            _vm.encumbrance && _vm.encumbrance.length
              ? _c("span", { staticClass: "text-muted risk-collapse__count" }, [
                  _vm._v(
                    "\n        найдено " +
                      _vm._s(_vm.encumbrance.length) +
                      "\n        " +
                      _vm._s(
                        _vm.pluralizeMsg("encumbrance", _vm.encumbrance.length)
                      ) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "ml-auto mr-2" }, [
              !_vm.egrnObject
                ? _c(
                    "span",
                    { directives: [{ name: "tippy", rawName: "v-tippy" }] },
                    [
                      _c("svg", [
                        _c("use", {
                          attrs: { "xlink:href": "#icon-outline-info" }
                        })
                      ]),
                      _c("div", { attrs: { "data-tippy-content": "hide" } }, [
                        _vm._v(
                          "\n            Доступно после заказа\n            "
                        ),
                        _c("b", [
                          _vm._v("Отчеты из ЕГРН об объекте недвижимости")
                        ])
                      ])
                    ]
                  )
                : !_vm.encumbrance
                ? _c("b", { staticClass: "text-success" }, [
                    _vm._v("\n          не найдено\n        ")
                  ])
                : _c("span", { staticClass: "risk-collapse__expand-text" }, [
                    _vm._v("подробнее")
                  ])
            ])
          ]),
          _vm._l(_vm.encumbrance, function(enc, i) {
            return _c("div", { key: i, staticClass: "encumbrance-item" }, [
              _c("div", { staticClass: "px-1 mb-1" }, [
                _c("b", [_vm._v(" " + _vm._s(i + 1) + ". ")]),
                _c("span", [_vm._v(_vm._s(_vm.e.type_name))])
              ]),
              _vm.hasEncumbranceData(enc)
                ? _c(
                    "table",
                    { staticClass: "table table-sm table-bordered" },
                    [
                      _c("tbody", [
                        enc.reg_date
                          ? _c("tr", [
                              _c("td", [
                                _vm._v(
                                  "\n              Дата регистрации\n            "
                                )
                              ]),
                              _c("td", [_vm._v(_vm._s(enc.reg_date))])
                            ])
                          : _vm._e(),
                        enc.duration && enc.duration.started
                          ? _c("tr", [
                              _c("td", [
                                _vm._v(
                                  "\n              Продолжительность сделки\n            "
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  "\n              с " +
                                    _vm._s(enc.duration.started) +
                                    "\n              "
                                ),
                                enc.duration.stopped && !enc.duration.term
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                до " +
                                          _vm._s(enc.duration.stopped) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                enc.duration.term
                                  ? _c("span", [
                                      _vm._v(_vm._s(enc.duration.term))
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e(),
                        (enc.organizations && enc.organizations.length) ||
                        enc.owners_restriction_in_favorem
                          ? _c("tr", [
                              _c("td", [
                                _vm._v(
                                  "\n              Лицо, в пользу которого установлено ограничение прав и обременение\n            "
                                )
                              ]),
                              _c(
                                "td",
                                [
                                  _vm._l(enc.organizations, function(
                                    org,
                                    orgIndex
                                  ) {
                                    return _c("div", { key: orgIndex }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(org.content || org.name) +
                                          "\n              "
                                      )
                                    ])
                                  }),
                                  enc.owners_restriction_in_favorem
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              enc.owners_restriction_in_favorem
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                2
                              )
                            ])
                          : _vm._e(),
                        enc.docs && enc.docs.length
                          ? _c("tr", [
                              _c("td", [
                                _vm._v("Основание государственной регистрации:")
                              ]),
                              _c(
                                "td",
                                _vm._l(enc.docs, function(d, docIndex) {
                                  return _c("div", { key: docIndex }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(d.Content) +
                                        "\n              "
                                    )
                                  ])
                                }),
                                0
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                : _vm._e()
            ])
          })
        ],
        2
      ),
      _c(
        "app-collapse-item",
        { staticClass: "risk-collapse", attrs: { disabled: !_vm.rights } },
        [
          _c("template", { slot: "title" }, [
            !_vm.egrnObject
              ? _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#icon-lock" } })
                ])
              : !_vm.rights
              ? _c("svg", { staticClass: "text-warning" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-error-circle" } })
                ])
              : _c("svg", { staticClass: "text-success" }, [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-checkmark-circle" }
                  })
                ]),
            _c("span", { staticClass: "risk-collapse__title-text" }, [
              _vm._v("Собственники")
            ]),
            _vm.rights && _vm.rightsCount
              ? _c("span", { staticClass: "text-muted risk-collapse__count" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.rightsCount) +
                      " " +
                      _vm._s(_vm.pluralizeMsg("owner", _vm.rightsCount)) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "ml-auto mr-2" }, [
              !_vm.egrnObject
                ? _c(
                    "span",
                    { directives: [{ name: "tippy", rawName: "v-tippy" }] },
                    [
                      _c("svg", [
                        _c("use", {
                          attrs: { "xlink:href": "#icon-outline-info" }
                        })
                      ]),
                      _c("div", { attrs: { "data-tippy-content": "hide" } }, [
                        _vm._v(
                          "\n            Доступно после заказа\n            "
                        ),
                        _c("b", [
                          _vm._v("Отчеты из ЕГРН об объекте недвижимости")
                        ])
                      ])
                    ]
                  )
                : !_vm.rights
                ? _c("b", { staticClass: "text-warning" }, [
                    _vm._v("отсутствуют")
                  ])
                : _c("span", { staticClass: "risk-collapse__expand-text" }, [
                    _vm._v("подробнее")
                  ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "risk-list" },
            _vm._l(_vm.rights, function(rightItems, key) {
              return _c(
                "div",
                { key: key, staticClass: "risk-list-group" },
                [
                  _c("b", [_vm._v(_vm._s(key))]),
                  rightItems.organizations.length
                    ? _vm._l(rightItems.orgsCount, function(
                        orgRightCount,
                        orgName
                      ) {
                        return _c(
                          "div",
                          { key: orgName, staticClass: "risk-list-item" },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "risk-list-item__icon",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: { d: "M0 0h24v24H0z", fill: "none" }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"
                                  }
                                })
                              ]
                            ),
                            _c("div", { staticClass: "risk-list-item__text" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(orgName) +
                                  "\n              "
                              ),
                              orgRightCount > 1
                                ? _c("small", { staticClass: "d-block" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(orgRightCount) +
                                        " " +
                                        _vm._s(
                                          _vm.pluralizeMsg(
                                            "rights",
                                            orgRightCount
                                          )
                                        ) +
                                        "\n                на собственность\n              "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      })
                    : _vm._e(),
                  rightItems.persons.length
                    ? _vm._l(rightItems.personsCount, function(
                        personRightsCount,
                        personName
                      ) {
                        return _c(
                          "div",
                          { key: personName, staticClass: "risk-list-item" },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "risk-list-item__icon",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                                  }
                                }),
                                _c("path", {
                                  attrs: { d: "M0 0h24v24H0z", fill: "none" }
                                })
                              ]
                            ),
                            _c("div", { staticClass: "risk-list-item__text" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(personName) +
                                  "\n              "
                              ),
                              personRightsCount > 1
                                ? _c("small", { staticClass: "d-block" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(personRightsCount) +
                                        " " +
                                        _vm._s(
                                          _vm.pluralizeMsg(
                                            "rights",
                                            personRightsCount
                                          )
                                        ) +
                                        "\n                на собственность\n              "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      })
                    : _vm._e()
                ],
                2
              )
            }),
            0
          )
        ],
        2
      ),
      _c(
        "app-collapse-item",
        { staticClass: "risk-collapse" },
        [
          _c("template", { slot: "title" }, [
            !_vm.rightsHistory
              ? _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#icon-lock" } })
                ])
              : _vm.rightsHistory && _vm.rightsHistory.dates.length
              ? _c("svg", { staticClass: "text-success" }, [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-checkmark-circle" }
                  })
                ])
              : _c("svg", { staticClass: "text-warning" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-error-circle" } })
                ]),
            _c("span", { staticClass: "risk-collapse__title-text" }, [
              _vm._v("История по правообладателям")
            ]),
            _c("div", { staticClass: "ml-auto mr-2" }, [
              !_vm.egrnRightList
                ? _c(
                    "span",
                    { directives: [{ name: "tippy", rawName: "v-tippy" }] },
                    [
                      _c("svg", [
                        _c("use", {
                          attrs: { "xlink:href": "#icon-outline-info" }
                        })
                      ]),
                      _c("div", { attrs: { "data-tippy-content": "hide" } }, [
                        _vm._v(
                          "\n            Доступно после заказа\n            "
                        ),
                        _c("b", [
                          _vm._v(
                            "Выписки о переходе прав на объект недвижимости"
                          )
                        ])
                      ])
                    ]
                  )
                : _vm.rightsHistory && _vm.rightsHistory.dates.length
                ? _c("span", { staticClass: "risk-collapse__expand-text" }, [
                    _vm._v("\n          подробнее\n        ")
                  ])
                : _vm._e()
            ])
          ]),
          _vm.rightsHistory && _vm.rightsHistory.dates.length
            ? _c(
                "div",
                { staticClass: "risk-list" },
                _vm._l(_vm.rightsHistory.dates, function(date) {
                  return _c(
                    "div",
                    { key: date, staticClass: "risk-list-group" },
                    [
                      _c("b", { staticClass: "pl-0" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tippy",
                                rawName: "v-tippy",
                                value: {
                                  content: "Дата государственной регистрации",
                                  placement: "top-start"
                                },
                                expression:
                                  "{\n              content: 'Дата государственной регистрации',\n              placement: 'top-start',\n            }"
                              }
                            ]
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "20",
                                  height: "20",
                                  viewBox: "0 0 24 24"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
                                  }
                                }),
                                _c("path", {
                                  attrs: { fill: "none", d: "M0 0h24v24H0z" }
                                })
                              ]
                            ),
                            _c("span", { staticClass: "align-middle" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.rightsHistory.items[date].date) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._l(_vm.rightsHistory.items[date].persons, function(
                        person,
                        i
                      ) {
                        return _c(
                          "div",
                          { key: i, staticClass: "risk-list-item" },
                          [
                            _c("div", { staticClass: "risk-list-item__text" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(person.content) +
                                  "\n            "
                              ),
                              person.registration && person.registration.name
                                ? _c("small", { staticClass: "d-block" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(person.registration.name) +
                                        " - № " +
                                        _vm._s(person.registration.reg_number) +
                                        "\n              "
                                    ),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "tippy",
                                          rawName: "v-tippy",
                                          value: {
                                            content:
                                              "Номер государственной регистрации права"
                                          },
                                          expression:
                                            "{ content: 'Номер государственной регистрации права' }"
                                        }
                                      ],
                                      staticClass: "fa fa-question-circle"
                                    })
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              )
            : _c("ul", { staticClass: "text-muted mb-0 pl-3" }, [
                _c("li", [_vm._v("Дата")]),
                _c("li", [_vm._v("Правовая форма")]),
                _c("li", [_vm._v("Основание")])
              ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }