


































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

import { Validators } from '@/core/utils/validator';

@Component({
  popupOptions: {
    size: 'xs',
  },
  computed: {
    ...mapState('auth', [ 'user' ]),
  },
})
export default class DemoRequestDialog extends Vue {
  // props
  user: User;

  // data()
  model: SimpleObject<string> = {
    email: '',
    phone: '',
  };

  // lifecycle hooks
  created() {
    if (this.user) {
      this.model.phone = this.user.phone;
      this.model.email = this.user.email;
    }
  }
  // methods
  async submitForm() {
    const template = `
    Email: <a href="mailto:${this.model.email}">${this.model.email}</a>
    <br>
    Телефон: <a target="_blank" href="tel:${this.model.phone}">${this.model.phone}</a>`;

    try {
      await this.$api.events.SendEmail({
        template,
        title: 'Заявка о стоимости (с Демо страницы)',
        email: 'fazleev@realtycloud.ru',
      });
      this.$noty.success({ text: 'Заявка успешно отправлена' });
      this.$dialog.close();
    } catch (error) {
      this.$noty.error({ text: 'Произошла ошибка при отправке, попробуйте еще раз' });
    }
  }

  validationRules(): ValidationConfig {
    return {
      email: [ Validators.required, Validators.email ],
      phone: [ Validators.required, Validators.phone ],
    };
  }
}
