








































































import { Component, Vue } from 'vue-property-decorator';

// const MILLISECONDS_IN_HOUR = 36e5; // 60 * 60 * 1000

@Component({
  name: 'AppPurchasedProductItem',

  props: {
    item: Object,
    objectKey: String,
  },
})
export default class PurchasedProductItem extends Vue {
  // props
  item: Product;
  objectKey: string;

  // data
  downloadLoading: boolean = false;

  // computed
  get iconStatus(): string {
    return this.isCompleted ? '#icon-checkmark-circle' : '#icon-time';
  }

  get isCompleted(): boolean {
    return this.item.status === 'done';
  }

  get averageTime(): number {
    return this.$store.state.details.averageTime;
  }

  get requestTimeDiff(): number {
    let requestDateString = this.item.created_date;

    if (!requestDateString) {
      requestDateString = new Date().toString();
    }

    const currentDate = Date.now();
    const requestedDate = new Date(requestDateString).getTime();
    const diff = (currentDate - requestedDate) / (60 * 1000); // MILLISECONDS_IN_HOUR;

    const diffInMinutes = this.averageTime - diff;

    if (diffInMinutes > 0) {
      return diffInMinutes / 60;
    }

    return 0;
  }

  get remainingTimeMessage() {
    if (!this.requestTimeDiff) {
      return 'ожидайте ~ 5 часов';
    }

    return `ожидайте ~ ${Math.ceil(this.requestTimeDiff)} часов`;
  }

  // methods
  async download(url: string, fileType: string) {
    const fileName = `${this.item.product_name_ru}`;
    let response;
    try {
      response = await this.$api.raw.files.GetFileBlob(url);
    } catch (error) {
      return this.$api.HttpError(error);
    }

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(response, `${fileName}.${fileType}`);
      return;
    }

    const objUrl = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.setAttribute('style', 'display: none');
    document.body.appendChild(a);
    a.href = objUrl;
    a.download = fileName;
    a.click();
    // Таймаут необходим для Edge
    setTimeout(() => {
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    }, 500);
  }
}
