































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import forIn from 'lodash/forIn';
import countBy from 'lodash/countBy';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import find from 'lodash/find';

import pluralize from '@/core/utils/pluralize';

@Component({
  props: {
    purchases: Array,
  },
})
export default class StatementsInfo extends Vue {
  // props
  purchases: Product[];

  // data()
  productName: string = 'RiskAssessment';

  // computed
  get egrnObject(): any {
    return find(this.purchases, { product_name: 'EgrnObject', status: 'done' });
  }

  get egrnRightList(): any {
    return find(this.purchases, { product_name: 'EgrnRightList', status: 'done' });
  }

  get encumbrance(): any {
    if (get(this.egrnObject, 'data.encumbrance')) {
      const filteredEncumbrance = this.egrnObject.data.encumbrance.filter(
        (enc: any) => enc.name || enc.owners_restriction_in_favorem,
      );

      if (filteredEncumbrance.length) {
        return filteredEncumbrance;
      }
    }

    return null;
  }

  get rights(): any {
    if (get(this.egrnObject, 'data.rights')) {
      const rights: any = {};
      const filteredRights = this.egrnObject.data.rights.filter(
        (r: any) => r.registration != null,
      );
      const rightsByKey = groupBy(filteredRights, (r: any) => {
        const { registration } = r;

        if (!registration) {
          return 'null';
        }

        if (registration.text) {
          return `${registration.type_name} - ${registration.text}`;
        }

        return registration.type_name;
      });

      forIn(rightsByKey, (value: any[], key) => {
        const orgs: any = flatMap(value, (r: any) => flatMap(r.organizations));
        const owners: any = flatMap(value, (r: any) => flatMap(r.persons));
        const orgsCount = countBy(orgs, (o) => o.name);
        const personsCount = countBy(owners, (o) => o.content);

        rights[key] = {
          organizations: orgs,
          persons: owners,
          orgsCount,
          personsCount,
        };
      });

      return rights;
    }

    return null;
  }

  get rightsCount(): number {
    let rightsCount = 0;
    if (this.rights) {
      forIn(this.rights, (value) => {
        rightsCount += values(value.orgsCount).length + values(value.personsCount).length;
      });
    }
    return rightsCount;
  }

  get rightsHistory(): any {
    if (get(this.egrnRightList, 'data.rights[0]')) {
      const rights = this.egrnRightList.data.extract_object.rights[0];
      const items: any = {};
      let dates: string[] = [];

      if (rights) {
        let rightPersons: StatementEntity[] = rights.entities;
        rightPersons = rightPersons.filter((p: StatementEntity) => p.registration != null);
        rightPersons.sort(this.sortByDate('registration.reg_date'));

        dates = rightPersons.map((p: StatementEntity) => {
          return p.registration.reg_number.split('.')[0];
        });

        dates = uniq(dates);

        for (const p of rightPersons) {
          const { registration } = p;
          const regNumber = registration.reg_number.split('.')[0];

          if (!items[regNumber]) {
            items[regNumber] = {
              date: registration.reg_date,
              persons: [],
            };
          }

          items[regNumber].persons.push(p);
        }
      }

      return { dates, items };
    }

    return null;
  }
  // } computed end

  // methods
  pluralizeMsg(type: string, count: number) {
    let words: [string, string, string];

    switch (type) {
      case 'encumbrance':
        words = [ 'обременение', 'обременения', 'обременений' ];
        break;
      case 'owner':
        words = [ 'собственник', 'собственника', 'собственников' ];
        break;
      case 'rights':
        words = [ 'право', 'права', 'прав' ];
        break;
    }

    return pluralize(count, ...words);
  }

  sortByDate(dateKeyName: string = 'created_date') {
    return (prev: any, next: any) =>
      new Date(get(prev, dateKeyName)).getTime() - new Date(get(next, dateKeyName)).getTime();
  }
}
