





















import Vue from 'vue';

export default Vue.extend({
  name: 'AppObjectDetailInfo',

  filters: {
    shortObjectType: (value: string) => {
      const room = 'Помещение';
      const build = 'Здание';

      if (value.toLowerCase().indexOf(room.toLowerCase()) !== -1) {
        return room;
      }

      if (value.toLowerCase().indexOf(build.toLowerCase()) !== -1) {
        return build;
      }
    },
  },

  props: {
    objectInfo: Object as () => RealtyObject,
  },
});
