import { Component, Vue } from 'vue-property-decorator';
import noop from 'lodash/noop';

@Component({
  props: {
    checkout: {
      type: Function,
      default: noop,
    },
    info: Object,
  },
})
export default class BaseOrderMixin extends Vue {
  productName: string;
  checkout: (analyticsTarget: string) => void;

  get product(): Product {
    return this.$store.getters.getProduct({ product_name: this.productName } as Product);
  }

  get isProductPurchased(): boolean {
    const product = this.$store.getters['details/purchasedItem']({
      product_name: this.productName,
    } as Product);

    return product != null;
  }

  get orderRequest(): boolean {
    return this.$store.state.order.loading;
  }

  // methods
  pay(analyticsTarget: string) {
    this.$store.commit('order/changeItem', [ this.product.product_id ]);
    this.checkout(analyticsTarget);
  }
}
