





































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import VueScrollactive from 'vue-scrollactive';
import { mapState } from 'vuex';

import AppObjectDetail from './ObjectDetailDemo.vue';
import AppObjectDetailInfo from './components/ObjectDetailInfo.vue';
import AppObjectDemoPdfDialog from './components/ObjectDemoPdf.vue';
import AppDemoRequestDialog from './components/DemoRequestDialog.vue';
import AppInfrastructureMap from '@/components/layout/Dialogs/InfrastructureMap.vue';
import AppHeader from '@/components/layout/Header/Header.vue';
import AppFooter from '@/components/layout/Footer/Footer.vue';

Vue.use(VueScrollactive);

@Component({
  components: { AppObjectDetail, AppObjectDetailInfo, AppHeader, AppFooter },
  computed: {
    ...mapState([ 'products' ]),
  },
})
export default class ObjectDetailWrapper extends Vue {
  // computed types
  products: Product[];

  // data
  objectInfo: ObjectInfoResponse = null;
  loading: boolean = true;

  // lifecycle hooks
  async created() {
    const data = await this.$api.request({
      url: `${location.origin}/data/object-info-demo.json`,
    });

    this.objectInfo = data;
    this.loading = false;
  }

  // methods
  showInfrastructure() {
    this.$dialog.open({
      component: AppInfrastructureMap,
      props: {
        info: this.objectInfo.object,
      },
    });
  }

  showPdf() {
    this.$dialog.open({
      component: AppObjectDemoPdfDialog,
      props: {
        info: this.objectInfo.object,
        products: this.objectInfo.products,
      },
    });
  }

  showDemoRequestDialog() {
    this.$dialog.open({ component: AppDemoRequestDialog });
  }
}
