var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "object-detail-list section", attrs: { id: "info" } },
    [
      _c("h5", { staticClass: "section__title" }, [
        _vm._v("\n    Информация об объекте\n  ")
      ]),
      _vm._l(_vm.keysGroup, function(group, i) {
        return [
          _vm._l(group, function(key) {
            return [
              _vm.titles[key] && _vm.info[key]
                ? _c("div", { key: key, staticClass: "mb-2" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.titles[key]) + ":")]),
                    _vm._v(" " + _vm._s(_vm.info[key]) + "\n      ")
                  ])
                : _vm._e()
            ]
          }),
          _c("br", { key: i })
        ]
      }),
      _c(
        "div",
        { staticClass: "alert alert-info text-center" },
        [
          _c("p", [
            _vm._v(
              "\n      Информация выше дублируется из Кадастровой карты и может быть устаревшей. Актуальная\n      информация содержится в выписке из ЕГРН.\n    "
            )
          ]),
          _c(
            "app-button",
            {
              attrs: { variant: "primary-light", size: "sm" },
              on: {
                click: function($event) {
                  return _vm.$emit("change-tab", "statements")
                }
              }
            },
            [_vm._v("\n      Заказать выписку из ЕГРН\n    ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }