var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-item product-item_default",
      class: { "product-item_selected": _vm.active }
    },
    [
      _c(
        "div",
        { staticClass: "product-item__left" },
        [
          _c("app-checkbox", {
            attrs: { value: _vm.product.product_id, size: "lg" },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "product-item__body" }, [
        _c("div", { staticClass: "product-item__name" }, [
          _vm._v("\n      " + _vm._s(_vm.product.product_name_ru) + "\n    ")
        ]),
        _c("div", { staticClass: "product-item__desc" }, [
          _c("span", [_vm._v(_vm._s(_vm.product.product_description))])
        ])
      ]),
      _c("div", { staticClass: "product-item__right" }, [
        _c(
          "div",
          { staticClass: "product-item__info" },
          [
            _c(
              "app-button",
              {
                staticClass: "rounded-pill",
                attrs: { variant: "white", size: "sm" },
                on: { click: _vm.openInfoDialog }
              },
              [_vm._v("\n        Подробнее\n      ")]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "product-item__price",
            class: { "product-item__price_discount": _vm.coupon }
          },
          [
            _vm.coupon
              ? _c(
                  "div",
                  { staticClass: "badge badge-danger product-item__badge" },
                  [
                    _vm._v(
                      "\n        -" +
                        _vm._s(_vm.coupon.description) +
                        "\n      "
                    )
                  ]
                )
              : _vm._e(),
            _c("span", { staticClass: "product-item__price-original" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.product.product_price) + "\n        "
              ),
              _c("i", { staticClass: "fas fa-ruble-sign" })
            ]),
            _vm.coupon
              ? _c("span", { staticClass: "product-item__price-discount" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.product.product_price - _vm.coupon.discount) +
                      "\n        "
                  ),
                  _c("i", { staticClass: "fas fa-ruble-sign" })
                ])
              : _vm._e()
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }