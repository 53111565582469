import { render, staticRenderFns } from "./ObjectDetailInfo.vue?vue&type=template&id=9d626d56&"
import script from "./ObjectDetailInfo.vue?vue&type=script&lang=ts&"
export * from "./ObjectDetailInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/realtycloud/rcfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('9d626d56', component.options)
    } else {
      api.reload('9d626d56', component.options)
    }
    module.hot.accept("./ObjectDetailInfo.vue?vue&type=template&id=9d626d56&", function () {
      api.rerender('9d626d56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ObjectDetailDemo/components/ObjectDetailInfo.vue"
export default component.exports