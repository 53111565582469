import { render, staticRenderFns } from "./ProductItem.vue?vue&type=template&id=305b5564&"
import script from "./ProductItem.ts?vue&type=script&lang=ts&"
export * from "./ProductItem.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/realtycloud/rcfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('305b5564', component.options)
    } else {
      api.reload('305b5564', component.options)
    }
    module.hot.accept("./ProductItem.vue?vue&type=template&id=305b5564&", function () {
      api.rerender('305b5564', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ObjectDetailDemo/components/ProductItem/ProductItem.vue"
export default component.exports