var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c(
        "div",
        {
          staticClass:
            "demo-alert text-center alert alert-info border-left-0 border-top-0 border-right-0 rounded-0 m-0"
        },
        [
          _vm._v(
            "\n    Вы смотрите демо-версию отчета на квартиру. Чтобы использовать сервис\n    "
          ),
          _c(
            "strong",
            {
              staticClass: "btn-link font-weight-500",
              on: { click: _vm.showDemoRequestDialog }
            },
            [_vm._v("\n      оставьте заявку\n    ")]
          )
        ]
      ),
      _c("app-header", {
        attrs: { "hide-search": "", "prevent-navigation": "" }
      }),
      _c(
        "div",
        { staticClass: "container object-page demo d-print-none" },
        [
          _vm.loading || !_vm.products.length
            ? [
                _c(
                  "div",
                  { staticClass: "p-4 font-weight-500 text-muted" },
                  [
                    _c("app-loader", {
                      attrs: {
                        text:
                          "Пожалуйста подождите, идет запрос данных об объекте"
                      }
                    })
                  ],
                  1
                )
              ]
            : [
                _c(
                  "div",
                  { ref: "menu", staticClass: "menu-wrap section" },
                  [
                    _c(
                      "scrollactive",
                      {
                        staticClass: "object-detail-menu",
                        attrs: {
                          offset: 140,
                          "scroll-offset": 71,
                          "highlight-first-item": ""
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "object-detail-menu__item scrollactive-item",
                            attrs: { href: "#egrn" }
                          },
                          [_vm._v("Росреестр")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "object-detail-menu__item scrollactive-item",
                            attrs: { href: "#risk" }
                          },
                          [_vm._v("Риски")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "object-detail-menu__item scrollactive-item",
                            attrs: { href: "#assessment" }
                          },
                          [_vm._v("Оценка")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "object-detail-menu__item scrollactive-item",
                            attrs: { href: "#sales" }
                          },
                          [_vm._v("\n            История продаж\n          ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "object-detail-menu__item",
                            on: { click: _vm.showInfrastructure }
                          },
                          [_vm._v("\n            Инфраструктура\n          ")]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col demo-sidebar container_responsive" },
                    [
                      _c(
                        "div",
                        { staticClass: "section wrapper-padding_sm" },
                        [
                          _c("app-object-detail-info", {
                            attrs: { "object-info": _vm.objectInfo.object }
                          }),
                          _c(
                            "div",
                            { staticClass: "btn-wrap" },
                            [
                              _c(
                                "app-button",
                                {
                                  attrs: { size: "xl", block: "" },
                                  on: { click: _vm.showPdf }
                                },
                                [
                                  _vm._v(
                                    "\n                Скачать PDF\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._m(0)
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col container_responsive" },
                    [
                      _c("app-object-detail", {
                        attrs: {
                          info: _vm.objectInfo.object,
                          purchases: _vm.objectInfo.products,
                          products: _vm.products
                        },
                        on: { "print-pdf": _vm.showPdf }
                      })
                    ],
                    1
                  )
                ])
              ]
        ],
        2
      ),
      _c("app-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "section wrapper-padding_sm obj-stats d-lg-block d-none" },
      [
        _c("div", { staticClass: "obj-stats__title mb-2 text-center" }, [
          _vm._v("\n              Информация по объекту\n            ")
        ]),
        _c("div", { staticClass: "obj-stats-progress" }, [
          _c("div", { staticClass: "obj-stats-progress__progress progress" }, [
            _c("div", {
              staticClass: "obj-stats-progress__bar progress-bar",
              staticStyle: { width: "100%" }
            })
          ]),
          _c(
            "div",
            {
              staticClass:
                "obj-stats-progress__total text-muted small text-center"
            },
            [_vm._v("\n                Открыто 100% из 100%\n              ")]
          )
        ]),
        _c("ul", { staticClass: "obj-stats-list mb-0 pl-3 mt-2" }, [
          _c("li", [
            _c("div", { staticClass: "obj-stats-list__item py-1" }, [
              _vm._v("\n                  Данные из Росреестра "),
              _c("span", { staticClass: "text-success small" }, [
                _vm._v("+30%")
              ])
            ])
          ]),
          _c("li", [
            _c("div", { staticClass: "obj-stats-list__item py-1" }, [
              _vm._v("\n                  Отчет о рисках "),
              _c("span", { staticClass: "text-success small" }, [
                _vm._v("+40%")
              ])
            ])
          ]),
          _c("li", [
            _c("div", { staticClass: "obj-stats-list__item py-1" }, [
              _vm._v("\n                  Отчет о стоимости "),
              _c("span", { staticClass: "text-success small" }, [
                _vm._v("+15%")
              ])
            ])
          ]),
          _c("li", [
            _c("div", { staticClass: "obj-stats-list__item py-1" }, [
              _vm._v("\n                  История продаж "),
              _c("span", { staticClass: "text-success small" }, [
                _vm._v("+15%")
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }