var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section", attrs: { id: "sales" } }, [
    _c("div", { staticClass: "wrapper-padding_sm pb-0" }, [
      _c("div", { staticClass: "section__title" }, [
        _c("h5", [_vm._v("История продаж по дому")]),
        _c(
          "div",
          {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { placement: "top-end", theme: "light text-info" },
                expression: "{ placement: 'top-end', theme: 'light text-info' }"
              }
            ],
            staticClass: "section__title-info"
          },
          [
            _vm._v("\n        Об услуге "),
            _c("svg", [
              _c("use", { attrs: { "xlink:href": "#icon-fill-info" } })
            ]),
            _vm._m(0)
          ]
        )
      ])
    ]),
    _vm.loading
      ? _c("div", { staticClass: "p-3" }, [_c("app-loader")], 1)
      : _vm.historyItems.length && !_vm.loading
      ? _c("div", [
          _c(
            "div",
            { staticClass: "sales-history-table" },
            [
              _vm._m(1),
              _vm._l(_vm.limitItems, function(item, index) {
                return _c(
                  "div",
                  {
                    key: item.address_id + index,
                    staticClass: "sales-history-item"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "sales-history-item__col sales-history-item__col_img"
                      },
                      [
                        _c("app-property-image", {
                          attrs: { photos: item.photos }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "sales-history-item__col sales-history-item__col_info"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.area) +
                            " м², " +
                            _vm._s(item.rooms) +
                            " комнатная\n          "
                        ),
                        _c(
                          "div",
                          { staticClass: "sales-history-item__extra-info" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.level) +
                                " этаж\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "sales-history-item__col sales-history-item__col_price-start"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "sales-history-item__col-title" },
                          [_vm._v("\n            Начальная цена\n          ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "sales-history-item__price" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("currency")(item.price_start)) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "sales-history-item__extra-info" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("currency")(
                                    item.price_start / item.area
                                  )
                                ) +
                                " за м²\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "sales-history-item__col sales-history-item__col_price-end"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "sales-history-item__col-title" },
                          [_vm._v("\n            Цена при снятии\n          ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "sales-history-item__price" },
                          [
                            item.price_end !== item.price_start
                              ? _c("i", {
                                  staticClass: "fas",
                                  class: {
                                    "fa-long-arrow-alt-up text-danger":
                                      item.price_end > item.price_start,
                                    "fa-long-arrow-alt-down text-success":
                                      item.price_end < item.price_start
                                  }
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("currency")(item.price_end)) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "sales-history-item__extra-info" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("currency")(item.price_end / item.area)
                                ) +
                                " за м²\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "sales-history-item__col sales-history-item__col_date"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "sales-history-item__date-item" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "sales-history-item__date-text sales-history-item__col-title"
                              },
                              [
                                _vm._v(
                                  "\n              Опубликовано\n            "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("date")(item.date_start, "DD.MM.YYYY")
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        item.date_end
                          ? _c(
                              "div",
                              { staticClass: "sales-history-item__date-item" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "sales-history-item__date-text sales-history-item__col-title"
                                  },
                                  [
                                    _vm._v(
                                      "\n              Дата снятия\n            "
                                    )
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("date")(
                                        item.date_end,
                                        "DD.MM.YYYY"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "sales-history-item__date-item" },
                              [
                                _c("div", {
                                  staticClass: "sales-history-item__col-title"
                                }),
                                _c("div", { staticClass: "text-success" }, [
                                  _vm._v(
                                    "\n              Активное\n            "
                                  )
                                ])
                              ]
                            )
                      ]
                    )
                  ]
                )
              })
            ],
            2
          ),
          _vm.limit < _vm.historyItems.length
            ? _c("div", { staticClass: "text-center py-3" }, [
                _c(
                  "span",
                  { staticClass: "btn-link", on: { click: _vm.showMore } },
                  [_vm._v("\n        Показать еще\n      ")]
                )
              ])
            : _vm._e()
        ])
      : _c("div", { staticClass: "wrapper-padding_sm pt-0" }, [
          _c("div", { staticClass: "alert alert-info mb-0 text-center" }, [
            _vm._v(
              "\n      К сожалению, по этому адресу ничего не найдено\n    "
            )
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-left", attrs: { "data-tippy-content": "hide" } },
      [
        _c("div", { staticClass: "mb-2" }, [
          _vm._v(
            "\n            Показывает все объявления из этого дома, которые выкладывались на продажу в популярных\n            досках объявлений за последние 2 года.\n          "
          )
        ]),
        _vm._v(
          "\n          Данная информация позволяет понять тенденцию - рост или спад цены за квадратный метр и\n          кол-во сделок.\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "sales-history-table__head sales-history-item" },
      [
        _c("div", {
          staticClass: "sales-history-item__col sales-history-item__col_img"
        }),
        _c("div", { staticClass: "sales-history-item__col" }, [
          _vm._v("\n          Квартира\n        ")
        ]),
        _c("div", { staticClass: "sales-history-item__col" }, [
          _vm._v("\n          Начальная цена\n        ")
        ]),
        _c("div", { staticClass: "sales-history-item__col" }, [
          _vm._v("\n          Конечная цена\n        ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }