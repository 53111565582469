var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section", attrs: { id: "assessment" } }, [
    _c(
      "div",
      { staticClass: "wrapper-padding_sm assessment-form border-top" },
      [
        _c("div", { staticClass: "section__title" }, [
          _c("h5", [_vm._v("Оценка стоимости")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { placement: "top-end", theme: "light text-info" },
                  expression:
                    "{ placement: 'top-end', theme: 'light text-info' }"
                }
              ],
              staticClass: "section__title-info"
            },
            [
              _vm._v("\n        Об услуге "),
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#icon-fill-info" } })
              ]),
              _vm._m(0)
            ]
          )
        ]),
        _c(
          "app-form",
          {
            attrs: { rules: _vm.validationRules, model: _vm.model },
            on: { submit: _vm.onSubmit }
          },
          [
            _c("div", { staticClass: "assessment-form__top row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass:
                        "assessment-form__field assessment-form__field_full",
                      attrs: { "model-name": "address", label: "Адрес" }
                    },
                    [
                      _c("app-input", {
                        model: {
                          value: _vm.model.address,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "address", $$v)
                          },
                          expression: "model.address"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-auto d-md-block d-none" },
                [
                  _c(
                    "app-button",
                    { attrs: { type: "submit", loading: _vm.loading } },
                    [_vm._v("\n            Расчитать\n          ")]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "assessment-form__bottom row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "assessment-form__field",
                      attrs: {
                        "model-name": "area",
                        label: "Площадь",
                        tooltip: ""
                      }
                    },
                    [
                      _c(
                        "app-input",
                        {
                          model: {
                            value: _vm.model.area,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "area", $$v)
                            },
                            expression: "model.area"
                          }
                        },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("м"),
                            _c("sup", [_vm._v("2")])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "assessment-form__field",
                      attrs: {
                        "model-name": "rooms",
                        label: "Кол-во комнат",
                        tooltip: ""
                      }
                    },
                    [
                      _c("app-input", {
                        model: {
                          value: _vm.model.rooms,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "rooms", $$v)
                          },
                          expression: "model.rooms"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "assessment-form__field",
                      attrs: {
                        "model-name": "level",
                        label: "Этаж",
                        tooltip: ""
                      }
                    },
                    [
                      _c("app-input", {
                        model: {
                          value: _vm.model.level,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "level", $$v)
                          },
                          expression: "model.level"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "assessment-form__field",
                      attrs: {
                        "model-name": "levels",
                        label: "Этажность",
                        tooltip: ""
                      }
                    },
                    [
                      _c("app-input", {
                        model: {
                          value: _vm.model.levels,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "levels", $$v)
                          },
                          expression: "model.levels"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "assessment-form__field",
                      attrs: {
                        "model-name": "buildingType",
                        label: "Тип дома",
                        tooltip: ""
                      }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.object_type,
                              expression: "model.object_type"
                            }
                          ],
                          staticClass: "custom-select form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.model,
                                "object_type",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v(
                              "\n                Не выбрано\n              "
                            )
                          ]),
                          _vm._l(_vm.buildingTypes, function(type, index) {
                            return _c(
                              "option",
                              { key: type, domProps: { value: index } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(type) +
                                    "\n              "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c(
              "app-button",
              {
                staticClass: "d-md-none",
                attrs: { loading: _vm.loading, type: "submit", block: "" }
              },
              [_vm._v("\n        Расчитать\n      ")]
            )
          ],
          1
        )
      ],
      1
    ),
    _vm.response && _vm.response.cost > 0
      ? _c("div", { staticClass: "estimate-result wrapper-padding_sm pt-0" }, [
          _c("h6", { staticClass: "font-weight-normal" }, [
            _vm._v("\n      Средняя оценочная стоимость Вашей квартиры:\n    ")
          ]),
          _c("h4", { staticClass: "mb-4" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("currency")(_vm.response.cost)) +
                "\n    "
            )
          ]),
          _c("p", [_vm._v("Объекты на основе которых был проведен расчет")]),
          _c(
            "table",
            {
              staticClass:
                "table table-sm table-borderless estimate-result__table"
            },
            [
              _vm._m(1),
              _vm._l(_vm.sampleObjects, function(obj) {
                return _c("tbody", { key: obj.ID }, [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "estimate-result__img-wrap",
                        attrs: { rowspan: "2" }
                      },
                      [
                        _c("app-property-image", {
                          attrs: { photos: obj.Photos }
                        })
                      ],
                      1
                    ),
                    _c("td", { staticClass: "estimate-result__rooms" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(obj.Rooms) +
                          " - комнатная\n          "
                      )
                    ]),
                    _c("td", { staticClass: "estimate-result__area" }, [
                      _vm._v("\n            " + _vm._s(obj.Area) + " м"),
                      _c("sup", [_vm._v("2")])
                    ]),
                    _c("td", { staticClass: "estimate-result__level" }, [
                      _vm._v("\n            " + _vm._s(obj.Level) + " "),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("из " + _vm._s(obj.Levels))
                      ])
                    ]),
                    _c("td", { staticClass: "estimate-result__distance" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("formatMeter")(obj.Distance)) +
                          "\n          "
                      )
                    ]),
                    _c("td", { staticClass: "estimate-result__price" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: { placement: "top" },
                              expression: "{ placement: 'top' }"
                            }
                          ]
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-chart-line mr-2",
                            class: obj.PriceChanges
                              ? "text-success"
                              : "text-muted"
                          }),
                          _vm._v(
                            "\n\n              " +
                              _vm._s(_vm._f("currency")(obj.Price)) +
                              "\n\n              "
                          ),
                          obj.PriceChanges
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "price-changes",
                                    attrs: { "data-tippy-content": "hide" }
                                  },
                                  [
                                    _c("h6", [_vm._v("Изменение цены:")]),
                                    _vm._l(obj.PriceChanges, function(item, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "price-changes-item"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "price-changes-item__date"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("date")(
                                                    item.date,
                                                    "DD.MM.YYYY"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "price-changes-item__price"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(item.price)
                                                )
                                              )
                                            ]
                                          ),
                                          item.difference !== 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "price-changes-item__difference",
                                                  class:
                                                    item.difference < 0
                                                      ? "text-success"
                                                      : "text-danger"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        item.difference > 0
                                                          ? "+"
                                                          : ""
                                                      ) +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          item.difference
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            : [
                                _c(
                                  "div",
                                  { attrs: { "data-tippy-content": "hide" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(
                                          obj.UploadAt,
                                          "DD.MM.YYYY"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "estimate-result__address",
                        attrs: { colspan: "5" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(obj.Address) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ])
              })
            ],
            2
          ),
          _vm.samplesLimit < _vm.response.sample_size
            ? _c(
                "div",
                {
                  staticClass: "estimate-result__more text-center pb-3",
                  on: { click: _vm.showMoreSamples }
                },
                [
                  _c("span", { staticClass: "btn-link" }, [
                    _vm._v("Показать еще")
                  ])
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-left", attrs: { "data-tippy-content": "hide" } },
      [
        _c("div", { staticClass: "mb-2" }, [
          _vm._v(
            "\n            Средняя оценочная стоимость формируется на основе данных из Авито, Циан,\n            Яндекс.Недвижимости по квартирам со схожими характеристиками за последние 2 года.\n          "
          )
        ]),
        _vm._v(
          "\n          Искусственный интеллект учитывает более 40 параметров: Технические характеристики\n          объекта (тип дома, площадь, этаж, дата постройки дома, тип стен и др) и инфраструктурные\n          составляющие (транспортная доступность, магазины, школы, садики и др.)\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Квартира")]),
        _c("th", [_vm._v("Площадь")]),
        _c("th", [_vm._v("Этаж")]),
        _c("th", [_vm._v("Расстояние")]),
        _c("th", [_vm._v("Цена")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }